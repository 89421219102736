import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ArrowDownNarrowWide,
  ArrowUpNarrowWide,
  ChevronDown,
  Loader2,
  Pencil,
  Plus,
  Trash2,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Column,
  RelationOption,
  Table as TableConfig,
} from "@/interface/config";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useEffect, useState, useCallback, useRef } from "react";
import { DataEditor } from "./Editor";
import { Badge } from "@/components/ui/badge";
import _ from "lodash";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";
import CreateTableModal from "./CreateTableModal";
import DeleteTableModal from "./DeleteTableModal";
import CreateColumnModal from "./CreateColumnModal";
import DeleteColumnModal from "./DeleteColumnModal";
import { newColumn, newTable } from "@/lib/baseData";
import { filePost, post, sOptions } from "@/lib/rest";
import { useParams } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { getIconByFieldType } from "./form-ui";
import { sfetch } from "@themarblejar/stark-fetch";
import { cellReadOnlyRenderer } from "./cell-renderer";
import { CellChange, TableChange } from "@/interface/base";
import { getTable } from "@/lib/utils";
import DiffView from "./DiffView";

enum ModalType {
  None = "None",
  CreateTable = "CreateTable",
  EditTable = "EditTable",
  DeleteTable = "DeleteTable",
  CreateColumn = "CreateColumn",
  EditColumn = "EditColumn",
  DeleteColumn = "DeleteColumn",
  ShowDiff = "ShowDiff",
}

interface ColumnWidth {
  key: string;
  width: number;
}

const DatabaseTable = () => {
  const { config, setConfig, oldConfig, project, fetch } = useDatabaseConfig();
  const { projectId } = useParams();

  const [isEditorMode, setIsEditorMode] = useState(false);
  const [script, setScript] = useState<any>("");
  const [selectedTable, setSelectedTable] = useState<TableConfig>(
    config.tables[0],
  );
  const [selectedColumn, setSelectedColumn] = useState<Column>(
    newColumn().newColumn,
  );
  const [selectedCell, setSelectedCell] = useState<any>(null);
  const [showModal, setShowModal] = useState<ModalType>(ModalType.None);
  const [syncing, setSyncing] = useState(false);
  const [changes, setChanges] = useState<CellChange[]>([]);
  const [columnWidths, setColumnWidths] = useState<ColumnWidth[]>([]);
  const [isResizing, setIsResizing] = useState(false);
  const isResizingRef = useRef(false);
  const startXRef = useRef(0);

  useEffect(() => {
    if (config.tables.length === 1 && config.tables[0].columns.length === 0) {
      setSelectedTable(config?.tables[0]);
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(config, null, "\t") !== script) {
      setScript(JSON.stringify(config, null, "\t"));
    }
    if (selectedTable) {
      setSelectedTable(
        _.find(config.tables, { id: selectedTable.id }) || config?.tables[0],
      );
    }
  }, [config]);

  const dataQuery = sfetch({ ...sOptions, neonProjectId: projectId || "" })
    .table(selectedTable.name)
    // .withRelation(
    //   selectedTable.columns
    //     .filter((col: Column) => col.type === "relation")
    //     .map((col: Column) => col.name)
    //     .join(","),
    // )
    .version("v2")
    .getMany({ enabled: selectedTable !== null, overRideKey: `database-${projectId}-${selectedTable.name}` });

  const dataMutation = sfetch({ ...sOptions, neonProjectId: projectId || "" })
    .table(selectedTable.name)
    .update();

  function updateModal(
    modalType: ModalType,
    column?: Column,
    table?: TableConfig,
  ) {
    if (!column && !table) return;
    setShowModal(modalType);
    if (column) setSelectedColumn(column);
    if (table) setSelectedTable(table);
  }

  async function saveScript() {
    setConfig(JSON.parse(script));
    saveConfig(JSON.parse(script));
  }

  async function saveConfig(configuration: any) {
    let data = JSON.stringify({
      newConfiguration: configuration,
    });

    const response = await post({
      url: `/api/project/${projectId}/configuration`,
      data,
    });
    if (response.status === 200) {
      toast({
        variant: "default",
        title: "Configuration saved successfully",
      });
      fetch(projectId);
    } else {
      toast({
        variant: "destructive",
        title: "Failed to save configuration",
        description: "Please try again later",
      });
    }
  }

  const showDiff = () => {
    setShowModal(ModalType.ShowDiff);
  };

  const syncDatabase = async () => {
    setSyncing(true);
    await saveConfig(config);
    try {
      await post({ url: `/api/database/${projectId}/sync`, data: {} });
      toast({
        variant: "default",
        title: "Database synced successfully",
      });
      fetch(projectId);
      setSyncing(false);
      closeModal();
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error syncing database",
        description: error.message,
      });
      setSyncing(false);
      closeModal();
    }
  };

  const updateChanges = (
    key: string,
    value: string,
    data: any,
    column: Column,
  ) => {
    const presentChange = changes.find(change => change.key === key);

    if (presentChange) {
      if (presentChange.data[column.name] === value) {
        setChanges([...changes.filter(change => change.key !== key)]);
      } else {
        const updateChange = { ...presentChange, value };
        setChanges([
          ...changes.filter(change => change.key !== key),
          updateChange,
        ]);
      }
    } else {
      setChanges([
        ...changes,
        { key, value, data, column, table: selectedTable },
      ]);
    }
  };

  const saveChanges = async () => {
    const tableChanges: TableChange[] = [];
    changes.forEach(async change => {
      const hasTable = tableChanges.find(
        tableChange => tableChange.tableName === change.table.name,
      );
      if (hasTable) {
        hasTable.data[change.column.name] = change.value;
      } else {
        const { createdOn, updatedOn, ...data } = change.data;
        tableChanges.push({
          tableName: change.table.name,
          data: { ...data, [change.column.name]: change.value },
        });
      }
    });

    const tableChange = tableChanges[0];
    await dataMutation.mutate(tableChange.data);

    setChanges([]);
  };

  const cancelChanges = () => {
    setChanges([]);
  };

  const closeModal = () => {
    setShowModal(ModalType.None);
  };

  const addChildRelationToColumn = (column: Column) => {
    let decoratedColumn = { ...column };
    if (column.type === "relation") {
      const childTable = getTable(
        config,
        (column.options as RelationOption).tableId,
      );
      const childColumn = childTable.columns.filter(
        col => col.id === (column.options as RelationOption).columnId,
      )[0];
      decoratedColumn.options = {
        ...decoratedColumn.options,
        backColumnName: childColumn.name,
        hasMultipleBack: (childColumn.options as RelationOption).hasMultiple,
      } as any;
    }

    return decoratedColumn;
  };

  const callAI = async () => {
    const response = await post({
      url: `/api/database/${projectId}/aiGenerate`,
      data: {
        userPrompt: "i want to retrieve all the columns from the order table.",
      },
    });
    console.log(response);
  };

  const testMutation = sfetch({ ...sOptions, neonProjectId: projectId || "" }).table("post").create();
  const testUpdateMutation = sfetch({ ...sOptions, neonProjectId: projectId || "" }).table("post").update();

  const testButton = async () => {
    const files = [new File(["profileexample5"], "profileImage5.txt", { type: "text/plain" }), new File(["fooexample2"], "foo.txt", { type: "text/plain" })];

    const user = {
      firstName: "user2",
      email: "user2@mail.com",
      profileImage: files[0],
    }

    const newPost = {
      title: "post18",
      content: "post5 content",
      readTime: 30,
      publishedTime: null
    }

    const posts = [{
      title: "post8",
      content: "post5 content",
      readTime: 10.5
    }, {
      title: "post9",
      content: "post6 content",
      readTime: 10.89
    }, {
      title: "post10",
      content: "post7 content"
    }]

    const deletePost = ["01948d33-34b8-7d27-aa59-75e83a9c494b"]

    const updatePost = {
      id: "01948af7-1e4c-74be-996e-d15150d93f76",
      "postGallery+": [new File(["galleryexample6"], "gallery6.txt", { type: "text/plain" })],
      "postGallery-": ["01948059-08ec-70ec-8df7-b1a29f1f2079/0193f656-093a-70bf-a81c-0105fe91cb2c/fbA1SMbHBR-gallery6.txt"]
    }

    const res = await testMutation.mutate(newPost);
    // const res = await testUpdateMutation.mutate(updatePost);
  }

  function renderEditor() {
    return (
      <DataEditor
        configuration={config}
        script={script}
        setScript={setScript}
      />
    );
  }

  console.log(columnWidths);

  const handleResizeStart = useCallback((e: React.MouseEvent, columnKey: string) => {
    e.preventDefault();
    setIsResizing(true);
    isResizingRef.current = true;
    startXRef.current = e.pageX;
    const initialWidth = columnWidths.find(c => c.key === columnKey)?.width || 160;

    const handleMouseMove = (e: MouseEvent) => {
      if (isResizingRef.current) {
        const delta = e.pageX - startXRef.current;
        const newWidth = initialWidth + delta;

        setColumnWidths(prev => {
          const existing = prev.find(c => c.key === columnKey);
          if (existing) {
            return prev.map(c => c.key === columnKey ? { ...c, width: newWidth } : c);
          }
          return [...prev, { key: columnKey, width: newWidth }];
        });
      }
    };

    const handleMouseUp = () => {
      setIsResizing(false);
      isResizingRef.current = false;
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, []);

  function renderColumnHeader(
    key: string,
    name: string,
    type: string,
    column?: Column,
  ) {
    const width = columnWidths.find(c => c.key === key)?.width || 160;
    
    return (
      <Popover key={`${key}`}>
        <TableHead
          key={name}
          className="h-10 items-center border-r p-3 text-black relative"
          style={{ width: `${width}px`, minWidth: `${width}px`, maxWidth: `${width}px` }}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center min-w-0 flex-1">
              <div className="mr-2 flex-shrink-0">{getIconByFieldType(type)}</div>
              <span className="truncate">{name}</span>
            </div>
            <div className="flex items-center flex-shrink-0">
              <PopoverTrigger>
                <ChevronDown className="h-4 w-4" />
              </PopoverTrigger>
              <div
                className="absolute right-0 top-0 h-full w-1 cursor-col-resize hover:bg-slate-300"
                onMouseDown={(e) => handleResizeStart(e, key)}
              />
            </div>
          </div>
        </TableHead>
        <PopoverContent className="w-[200px] p-2 font-normal">
          {name !== "id" && name !== "createdOn" && name !== "updatedOn" && (
            <>
              <Button
                variant="ghost"
                className="flex w-full items-center justify-start"
                onClick={() => updateModal(ModalType.EditColumn, column)}
              >
                <Pencil className="mr-2 h-4 w-4" />
                <>Edit</>
              </Button>{" "}
            </>
          )}
          <Button
            variant="ghost"
            className="flex w-full items-center justify-start"
            onClick={() => { }}
          >
            <ArrowUpNarrowWide className="mr-2 h-4 w-4" />
            <>Sort Ascending</>
          </Button>
          <Button
            variant="ghost"
            className="flex w-full items-center justify-start"
            onClick={() => { }}
          >
            <ArrowDownNarrowWide className="mr-2 h-4 w-4" />
            <>Sort Descending</>
          </Button>{" "}
          {name !== "id" && name !== "createdOn" && name !== "updatedOn" && (
            <>
              <Separator />
              <Button
                variant="ghost"
                className="flex w-full items-center justify-start text-red-600"
                onClick={() => updateModal(ModalType.DeleteColumn, column)}
              >
                <Trash2 className="mr-2 h-4 w-4" />

                <>Delete</>
              </Button>
            </>
          )}
        </PopoverContent>
      </Popover>
    );
  }

  function renderCell(
    key: string,
    value: string,
    data: any,
    column: Column | null,
  ) {
    const selected = column && selectedCell && selectedCell.key === key;
    const changed = changes.find(change => change.key === key);
    let cellValue = changed
      ? changes.find(change => change.key === key)?.value
      : value;
    if (column?.type === "json") {
      cellValue = JSON.stringify(cellValue, null, 2);
    }

    const columnKey = `${selectedTable.name}.${key.split('.')[1]}`;
    const width = columnWidths.find(c => c.key === columnKey)?.width || 160;

    return (
      <TableCell
        key={key}
        onClick={() => {
          setSelectedCell({ key, column });
        }}
        className={`h-10 ${selected ? "border-2 border-green-300" : "border-r"} ${changed && "bg-green-200"} p-3`}
        style={{ width: `${width}px`, minWidth: `${width}px`, maxWidth: `${width}px`, overflow: 'hidden' }}
      >
        <div className="w-full overflow-hidden">
          {selected && column ? (
            <div className="w-full">
              <input
                className="w-full bg-transparent focus:outline-none text-ellipsis"
                value={cellValue}
                onChange={e => {
                  updateChanges(key, e.target.value, data, column);
                }}
              />
            </div>
          ) : (
            <div className="truncate">
              {cellReadOnlyRenderer(cellValue, column)}
            </div>
          )}
        </div>
      </TableCell>
    );
  }

  return (
    <>
      <div className="min-h-[calc(100vh-48px)] w-screen p-4">
        <div className="flex min-w-[1060px] items-center justify-between">
          <div className="flex items-center">
            <div className="mr-4 flex items-center space-x-2">
              <Switch
                id="editor-mode"
                checked={isEditorMode}
                onCheckedChange={() => setIsEditorMode(!isEditorMode)}
              />
              <Label htmlFor="editor-mode">Editor Mode</Label>
            </div>
            <Button
              variant={"secondary"}
              className="mr-4 bg-green-300"
              onClick={showDiff}
            >
              Sync Database
            </Button>
            {/* <Button className="mr-4" onClick={callAI}>
              Test AI
            </Button>
            <Button className="mr-4" onClick={testButton}>
              Test
            </Button> */}
            {isEditorMode && (
              <Button
                variant={"default"}
                onClick={() => saveScript()}
                className="mr-4"
              >
                Save Configuration
              </Button>
            )}
            {!isEditorMode && (
              <Button
                variant={"default"}
                onClick={() => saveConfig(config)}
                className="mr-4"
              >
                Save Configuration
              </Button>
            )}
            {changes.length > 0 && (
              <Button className="mr-4 bg-green-300" onClick={saveChanges}>
                Save Changes ({changes.length})
              </Button>
            )}
            {changes.length > 0 && (
              <Button
                variant={"destructive"}
                className="mr-4"
                onClick={cancelChanges}
              >
                Cancel Changes
              </Button>
            )}
            <div className="flex flex-col items-start">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="font-semibold">{project.name}</div>
                  </TooltipTrigger>
                  <TooltipContent>Project Name</TooltipContent>
                </Tooltip>
                <div className="font-mono text-xs text-gray-600">{ }</div>
              </TooltipProvider>
            </div>
          </div>
        </div>
        {!isEditorMode && (
          <div className="flex h-full">
            <Card className="mr-6 mt-6 h-[calc(100vh-152px)] min-w-60 overflow-y-scroll">
              <CardHeader>
                <CardTitle>Tables</CardTitle>
              </CardHeader>
              <CardContent className="p-4 pt-0">
                <nav className="text-muted-foreground grid gap-4 text-sm">
                  {config?.tables && _.sortBy(config.tables, ["order", "name"]).map(
                    (table: TableConfig, index: number) => (
                      <div
                        key={`${index}tablenav`}
                        className="flex cursor-pointer items-center justify-between text-left"
                        onClick={() => {
                          setSelectedTable(table);
                          setChanges([]);
                        }}
                      >
                        <p
                          className={`${table.id === selectedTable.id ? "rounded-md bg-green-300 px-2 py-1" : "rounded-md px-2 py-1 text-neutral-900"}`}
                        >
                          {table.name}
                        </p>
                        <div className="flex items-center justify-center">
                          {oldConfig.tables.find(badgeTable =>
                            _.isEqual(badgeTable, table),
                          ) ? (
                            <></>
                          ) : (
                            <Badge
                              variant={"outline"}
                              className="ml-3 h-6 text-slate-400"
                            >
                              draft
                            </Badge>
                          )}
                          <Button
                            variant="ghost"
                            className="flex items-center justify-start p-1"
                            onClick={() =>
                              updateModal(ModalType.EditTable, undefined, table)
                            }
                          >
                            <Pencil className="h-4 w-6" />
                          </Button>
                          <Button
                            variant="ghost"
                            className="flex items-center justify-start p-1 text-red-600"
                            onClick={() => setShowModal(ModalType.DeleteTable)}
                          >
                            <Trash2 className="h-4 w-6" />
                          </Button>
                        </div>
                      </div>
                    ),
                  )}
                </nav>
                <Button
                  variant="outline"
                  size={"icon"}
                  className="mt-6 h-8 w-8 p-0"
                  onClick={() => setShowModal(ModalType.CreateTable)}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </CardContent>
            </Card>
            {config.tables && <div className="relative mt-6 overflow-x-scroll rounded-lg border border-slate-300">
              <Table className="h-[calc(100vh-182px)] w-full overflow-y-scroll rounded-t-lg text-[14px] font-medium text-black">
                <TableHeader className="relative h-[42px] w-full bg-slate-100">
                  <TableRow className="relative">
                    {renderColumnHeader(
                      `${selectedTable.name}.id`,
                      "id",
                      "uuid",
                    )}
                    {_.sortBy(selectedTable.columns, ["order"]).map(
                      (column: Column, index: number) =>
                        renderColumnHeader(
                          `${selectedTable.name}.${column.name}`,
                          column.name,
                          column.type,
                          column,
                        ),
                    )}
                    {renderColumnHeader(
                      `${selectedTable.name}.createdOn`,
                      "createdOn",
                      "date",
                    )}
                    {renderColumnHeader(
                      `${selectedTable.name}.updatedOn`,
                      "updatedOn",
                      "date",
                    )}
                    <TableHead className="h-[30px] w-fit border-r px-2 text-black">
                      <Button
                        variant={"ghost"}
                        className="m-auto h-8 w-8 p-px hover:bg-slate-200"
                        onClick={() => setShowModal(ModalType.CreateColumn)}
                      >
                        <Plus className="h-4 w-4 cursor-pointer rounded-lg p-0" />
                      </Button>
                    </TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody className="h-fit">
                  {dataQuery.isSuccess &&
                    dataQuery.data &&
                    dataQuery.data.data.length > 0 &&
                    dataQuery.data.data.map((rowData: any) => (
                      <TableRow className="h-11">
                        {renderCell(
                          `${selectedTable.name}.id`,
                          rowData.id,
                          rowData,
                          null,
                        )}
                        {_.sortBy(selectedTable.columns, ["order"]).map(
                          (column: Column) =>
                            renderCell(
                              `${selectedTable.name}.${column.name}.${rowData.id}`,
                              rowData[column.name],
                              rowData,
                              column,
                            ),
                        )}
                        {renderCell(
                          `${selectedTable.name}.createdOn`,
                          rowData.createdOn,
                          rowData,
                          null,
                        )}
                        {renderCell(
                          `${selectedTable.name}.updatedOn`,
                          rowData.updatedOn,
                          rowData,
                          null,
                        )}
                      </TableRow>
                    ))}
                  <TableRow />
                </TableBody>
              </Table>
            </div>}
          </div>
        )}
        {isEditorMode && renderEditor()}
      </div>
      {showModal === ModalType.CreateTable && (
        <CreateTableModal
          table={newTable().newTable}
          closeAction={closeModal}
        />
      )}
      {showModal === ModalType.EditTable && (
        <CreateTableModal
          table={{
            ...selectedTable,
            columns: [
              ...selectedTable.columns.map(column =>
                addChildRelationToColumn(column),
              ),
            ],
          }}
          closeAction={closeModal}
          isEditMode={true}
        />
      )}
      {showModal === ModalType.DeleteTable && (
        <DeleteTableModal table={selectedTable} closeAction={closeModal} />
      )}
      {showModal === ModalType.CreateColumn && (
        <CreateColumnModal
          table={selectedTable}
          column={newColumn().newColumn}
          closeAction={closeModal}
        />
      )}
      {showModal === ModalType.EditColumn && (
        <CreateColumnModal
          table={{
            ...selectedTable,
            columns: [
              ...selectedTable.columns.map(column =>
                addChildRelationToColumn(column),
              ),
            ],
          }}
          column={addChildRelationToColumn(selectedColumn)}
          closeAction={closeModal}
          isEditMode={true}
        />
      )}
      {showModal === ModalType.DeleteColumn && (
        <DeleteColumnModal
          table={selectedTable}
          column={selectedColumn}
          closeAction={closeModal}
        />
      )}
      {showModal === ModalType.ShowDiff && (
        <DiffView
          syncing={syncing}
          isDeployment={false}
          oldCode={JSON.stringify(oldConfig, null, "\t")}
          newCode={JSON.stringify(config, null, "\t")}
          closeAction={closeModal}
          confirmAction={syncDatabase}
        />
      )}
    </>
  );
};

export default DatabaseTable;
