import React, { useState } from "react";
import {
  IWorkflowNode,
  SchemaProperty,
  UIComponentType,
  IWorkflowSettings,
} from "../../interface/workflow";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { InputField } from "./input-fields/InputField";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Switch } from "@/components/ui/switch";

interface ConfigPanelProps {
  selectedNode: IWorkflowNode | null;
  onUpdateParameters: (nodeId: string, key: string, value: any) => void;
  onUpdateLabel: (nodeId: string, newLabel: string) => void;
  onDelete: (nodeId: string) => void;
  onUpdateCredentials: (nodeId: string, key: string, value: any) => void;
  onUpdateActive: (nodeId: string, active: boolean) => void;
  workflowSettings?: IWorkflowSettings;
  onUpdateWorkflowSettings?: (settings: Partial<IWorkflowSettings>) => void;
}

const ConfigPanel: React.FC<ConfigPanelProps> = ({
  selectedNode,
  onUpdateParameters,
  onUpdateLabel,
  onDelete,
  onUpdateCredentials,
  onUpdateActive,
  workflowSettings,
  onUpdateWorkflowSettings,
}) => {
  const [openSections, setOpenSections] = useState<Record<string, boolean>>({
    errorHandling: true,
    execution: true,
    access: true,
  });

  const toggleSection = (key: string) => {
    setOpenSections(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  if (!selectedNode) {
    return (
      <div className="w-full border-l p-4">
        <h3 className="mb-4 text-lg font-medium">Workflow Settings</h3>
        <div className="space-y-4">
          {/* Error Handling Section */}
          <div className="border-b pb-2">
            <button
              onClick={() => toggleSection('errorHandling')}
              className="flex w-full items-center rounded-sm py-2 text-sm transition-colors hover:bg-gray-50"
            >
              <ChevronDown
                className={cn(
                  "h-4 w-4 transition-transform duration-200",
                  openSections.errorHandling ? "rotate-180 transform" : "",
                )}
              />
              <span className="flex-1 text-center font-medium">Error Handling</span>
              <div className="w-4" />
            </button>
            {openSections.errorHandling && (
              <div className="space-y-4 pt-2">
                <div>
                  <label className="mb-1 block text-sm font-medium">Retry Count</label>
                  <Input
                    type="number"
                    value={workflowSettings?.errorHandling?.retryCount || 0}
                    onChange={(e) => onUpdateWorkflowSettings?.({
                      errorHandling: {
                        ...workflowSettings?.errorHandling,
                        retryCount: parseInt(e.target.value),
                      }
                    })}
                    min={0}
                    className="w-full"
                  />
                </div>
                <div>
                  <label className="mb-1 block text-sm font-medium">Retry Delay (seconds)</label>
                  <Input
                    type="number"
                    value={workflowSettings?.errorHandling?.retryDelay || 0}
                    onChange={(e) => onUpdateWorkflowSettings?.({
                      errorHandling: {
                        ...workflowSettings?.errorHandling,
                        retryDelay: parseInt(e.target.value),
                      }
                    })}
                    min={0}
                    className="w-full"
                  />
                </div>
                <div>
                  <label className="mb-1 block text-sm font-medium">Failure Action</label>
                  <select
                    value={workflowSettings?.errorHandling?.failureAction || 'stop'}
                    onChange={(e) => onUpdateWorkflowSettings?.({
                      errorHandling: {
                        ...workflowSettings?.errorHandling,
                        failureAction: e.target.value as 'continue' | 'stop',
                      }
                    })}
                    className="w-full rounded-md border p-2"
                  >
                    <option value="stop">Stop</option>
                    <option value="continue">Continue</option>
                  </select>
                </div>
              </div>
            )}
          </div>

          {/* Execution Section */}
          <div className="border-b pb-2">
            <button
              onClick={() => toggleSection('execution')}
              className="flex w-full items-center rounded-sm py-2 text-sm transition-colors hover:bg-gray-50"
            >
              <ChevronDown
                className={cn(
                  "h-4 w-4 transition-transform duration-200",
                  openSections.execution ? "rotate-180 transform" : "",
                )}
              />
              <span className="flex-1 text-center font-medium">Execution</span>
              <div className="w-4" />
            </button>
            {openSections.execution && (
              <div className="space-y-4 pt-2">
                <div>
                  <label className="mb-1 block text-sm font-medium">Timeout (seconds)</label>
                  <Input
                    type="number"
                    value={workflowSettings?.execution?.timeout || 0}
                    onChange={(e) => onUpdateWorkflowSettings?.({
                      execution: {
                        ...workflowSettings?.execution,
                        timeout: parseInt(e.target.value),
                      }
                    })}
                    min={0}
                    className="w-full"
                  />
                </div>
                <div>
                  <label className="mb-1 block text-sm font-medium">Concurrency</label>
                  <Input
                    type="number"
                    value={workflowSettings?.execution?.concurrency || 1}
                    onChange={(e) => onUpdateWorkflowSettings?.({
                      execution: {
                        ...workflowSettings?.execution,
                        concurrency: parseInt(e.target.value),
                      }
                    })}
                    min={1}
                    className="w-full"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Access Section */}
          <div className="border-b pb-2">
            <button
              onClick={() => toggleSection('access')}
              className="flex w-full items-center rounded-sm py-2 text-sm transition-colors hover:bg-gray-50"
            >
              <ChevronDown
                className={cn(
                  "h-4 w-4 transition-transform duration-200",
                  openSections.access ? "rotate-180 transform" : "",
                )}
              />
              <span className="flex-1 text-center font-medium">Access</span>
              <div className="w-4" />
            </button>
            {openSections.access && (
              <div className="space-y-4 pt-2">
                <div>
                  <label className="mb-1 block text-sm font-medium">Execute Permission</label>
                  <Input
                    value={workflowSettings?.access?.execute || ''}
                    onChange={(e) => onUpdateWorkflowSettings?.({
                      access: {
                        ...workflowSettings?.access,
                        execute: e.target.value,
                      }
                    })}
                    placeholder="Enter execute permission"
                    className="w-full"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  const inputSchemas = selectedNode.data.definition?.input_schemas || {};

  return (
    <div className="w-full border-l p-4">
      <div className="space-y-4">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="text-sm font-medium">Node Label</label>
            <div className="flex items-center gap-2">
              <label className="text-sm text-muted-foreground">Active</label>
              <Switch
                checked={selectedNode.data.active}
                onCheckedChange={(checked) => onUpdateActive(selectedNode.id, checked)}
              />
            </div>
          </div>
        </div>
        <div>
          <label className="mb-1 block text-sm font-medium">Node Label</label>
          <Input
            value={selectedNode.data.label || ""}
            onChange={e => onUpdateLabel(selectedNode.id, e.target.value)}
            className="w-full"
          />
        </div>
        <div className="space-y-2">
          {Object.entries(inputSchemas).map(([schemaKey, schema]) => (
            <div key={schemaKey} className="border-b pb-2">
              <button
                onClick={() => toggleSection(schemaKey)}
                className="flex w-full items-center rounded-sm py-2 text-sm transition-colors hover:bg-gray-50"
              >
                <ChevronDown
                  className={cn(
                    "h-4 w-4 transition-transform duration-200",
                    openSections[schemaKey] ? "rotate-180 transform" : "",
                  )}
                />
                <span className="flex-1 text-center font-medium capitalize">
                  {schemaKey === "default" ? "General" : schemaKey}
                </span>
                <div className="w-4" />
              </button>
              {openSections[schemaKey] && (
                <div className="space-y-4 pt-2">
                  {Object.entries(schema.properties || {})
                    .sort(([, a], [, b]) => {
                      const orderA = (a as SchemaProperty).order ?? Infinity;
                      const orderB = (b as SchemaProperty).order ?? Infinity;
                      return orderA - orderB;
                    })
                    .map(([key, propertySchema]: [string, any]) => {
                      const currentValue =
                        propertySchema.ui?.component ===
                        UIComponentType.CREDENTIAL_SELECTOR
                          ? (selectedNode.data.credentials?.[key] ??
                            propertySchema.default ??
                            "")
                          : (selectedNode.data.parameters?.[key] ??
                            propertySchema.default ??
                            "");
                      return (
                        <InputField
                          key={key}
                          label={key}
                          value={currentValue}
                          onChange={value =>
                            propertySchema.ui?.component ===
                            UIComponentType.CREDENTIAL_SELECTOR
                              ? onUpdateCredentials(selectedNode.id, key, value)
                              : onUpdateParameters(selectedNode.id, key, value)
                          }
                          schema={propertySchema}
                          node={selectedNode}
                        />
                      );
                    })}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="border-t pt-4">
          <Button
            variant="destructive"
            onClick={() => onDelete(selectedNode.id)}
            className="w-full"
          >
            Delete Node
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfigPanel;
