import { Input } from "@/components/ui/input";
import { BaseInputProps } from "./types";

export const NumberInput: React.FC<BaseInputProps> = ({ value, onChange, schema }) => {
  const { min, max, step = 1 } = schema.ui || {};
  
  return (
    <Input
      type="number"
      value={value || ""}
      onChange={(e) => onChange(Number(e.target.value))}
      min={min}
      max={max}
      step={step}
      className="w-full"
    />
  );
}; 