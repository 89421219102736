import { useEffect } from "react";
import NavComponent from "@/custom/Navigation/NavComponent";
import { Outlet, useParams } from "react-router-dom";
import { useDatabaseConfig } from "@/hooks/useDatabaseConfig";

const Project = () => {
  const { projectId } = useParams();
  const { fetch, setConfig } = useDatabaseConfig();

  useEffect(() => {
    fetch(projectId);
    return () => {
      setConfig(null);
    };
  }, [projectId]);

  return (
    <div className="h-full w-full">
      <NavComponent />
      <Outlet />
    </div>
  );
};

export default Project;
