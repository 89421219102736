import React from "react";
import { DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select";
import Editor from "@monaco-editor/react";
import { IWorkflowCredential, CredentialType } from "@/interface/workflow";
import { CREDENTIAL_CATEGORIES, CREDENTIAL_ICONS } from "./constants";

interface CredentialDialogProps {
  credential: Partial<IWorkflowCredential>;
  onCredentialChange: (credential: Partial<IWorkflowCredential>) => void;
  onSave: () => Promise<void>;
  mode: 'create' | 'edit';
}

export function CredentialDialog({ credential, onCredentialChange, onSave, mode }: CredentialDialogProps) {
  const handleEditorChange = (value: string | undefined) => {
    try {
      if (!value) {
        onCredentialChange({ ...credential, data: {} });
        return;
      }

      const parsedData = JSON.parse(value);
      onCredentialChange({ ...credential, data: parsedData });
    } catch (e) {
      // Don't update if JSON is invalid
      console.log('Invalid JSON');
    }
  };

  return (
    <DialogContent className="sm:max-w-[600px]">
      <DialogHeader>
        <DialogTitle>{mode === 'edit' ? 'Edit' : 'Add New'} Credential</DialogTitle>
      </DialogHeader>
      <div className="grid gap-4 py-4">
        <div className="grid gap-2">
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            value={credential.name}
            onChange={(e) => onCredentialChange({ ...credential, name: e.target.value })}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="type">Type</Label>
          <Select
            value={credential.type}
            onValueChange={(value) => onCredentialChange({ ...credential, type: value as CredentialType })}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select credential type" />
            </SelectTrigger>
            <SelectContent>
              {Object.entries(CREDENTIAL_CATEGORIES).map(([category, types]) => (
                <SelectGroup key={category}>
                  <SelectLabel>{category}</SelectLabel>
                  {types.map((type) => (
                    <SelectItem key={type} value={type}>
                      <div className="flex items-center gap-2">
                        {CREDENTIAL_ICONS[type] && React.createElement(CREDENTIAL_ICONS[type]!, { size: 16 })}
                        <span>{type.replace(/_/g, ' ').toLowerCase()}</span>
                      </div>
                    </SelectItem>
                  ))}
                </SelectGroup>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="grid gap-2">
          <Label htmlFor="data">Data (JSON)</Label>
          <div className="h-[300px] border rounded-md">
            <Editor
              height="300px"
              defaultLanguage="json"
              value={JSON.stringify(credential.data, null, 2)}
              onChange={handleEditorChange}
              options={{
                minimap: { enabled: false },
                fontSize: 12,
                formatOnPaste: true,
                formatOnType: true,
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button onClick={onSave}>
          {mode === 'edit' ? 'Update' : 'Save'} Credential
        </Button>
      </div>
    </DialogContent>
  );
} 