import { BaseInputProps } from "./types";
import { TextInput } from "./TextInput";
import { NumberInput } from "./NumberInput";
import { SelectInput } from "./SelectInput";
import { CodeEditor } from "./CodeEditor";
import { CredentialSelector } from "./CredentialSelector";
import { UIComponentType } from "@/interface/workflow";

export const InputField: React.FC<BaseInputProps> = ({ value, onChange, schema, label, node }) => {
  const component = schema.ui?.component || UIComponentType.TEXT_INPUT;
  
  const renderInput = () => {
    switch (component) {
      case UIComponentType.TEXT_INPUT:
        return <TextInput value={value} onChange={onChange} schema={schema} />;
      case UIComponentType.NUMBER_INPUT:
        return <NumberInput value={value} onChange={onChange} schema={schema} />;
      case UIComponentType.SELECT:
        return <SelectInput value={value} onChange={onChange} schema={schema} />;
      case UIComponentType.CODE_EDITOR:
        return <CodeEditor value={value} onChange={onChange} schema={schema} />;
      case UIComponentType.CREDENTIAL_SELECTOR:
        return (
          <CredentialSelector
            value={value}
            onChange={onChange}
            credentialType={schema.ui?.credential_type}
          />
        );
      default:
        return <TextInput value={value} onChange={onChange} schema={schema} />;
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <label className="text-sm text-muted-foreground text-left">
        {label}
      </label>
      <div className="w-full">
        {renderInput()}
      </div>
    </div>
  );
}; 