import React, { useState } from "react";
import { IWorkflowNodeDefinition } from "../../interface/workflow";

interface NodePanelProps {
  nodeDefinitions: Record<string, IWorkflowNodeDefinition[]>;
}

const NodePanel: React.FC<NodePanelProps> = ({ nodeDefinitions }) => {
  const [expandedCategories, setExpandedCategories] = useState<
    Record<string, boolean>
  >(() =>
    Object.keys(nodeDefinitions).reduce(
      (acc, category) => ({
        ...acc,
        [category]: true,
      }),
      {},
    ),
  );

  const onDragStart = (event: React.DragEvent, nodeType: string) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const toggleCategory = (category: string) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <div className="h-screen w-full overflow-y-auto bg-white p-4 pb-14">
      <h3 className="mb-4 text-lg font-semibold">Nodes</h3>
      <div className="space-y-4">
        {Object.entries(nodeDefinitions).map(([category, definitions]) => (
          <div key={category} className="space-y-2">
            <button
              className="flex w-full items-center text-left font-medium text-gray-700 hover:text-gray-900"
              onClick={() => toggleCategory(category)}
            >
              <span className="mr-2">
                {expandedCategories[category] ? "▼" : "▶"}
              </span>
              {category}
            </button>

            {expandedCategories[category] && (
              <div className="ml-2 space-y-2">
                {definitions.map(definition => (
                  <div
                    key={definition.name}
                    className="cursor-move rounded-lg border p-3 hover:bg-gray-50"
                    draggable
                    onDragStart={e => onDragStart(e, definition.name)}
                  >
                    <div className="font-medium">{definition.display_name}</div>
                    <div className="text-sm text-gray-500">
                      {definition.description}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NodePanel;
