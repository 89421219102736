import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";

interface StructuredResultViewProps {
  value: any;
}

const StructuredResultView = ({ value }: StructuredResultViewProps) => {
  if (Array.isArray(value)) {
    // Handle array data with table view
    if (value.length === 0) return <div className="p-2 text-xs text-gray-500">Empty array</div>;
    
    const columns = Object.keys(value[0]);
    
    return (
      <div className="relative">
        <ScrollArea className="h-[200px] w-[300px]">
          <div style={{ width: 'max-content', minWidth: '100%' }}>
            <Table>
              <TableHeader className="sticky top-0 bg-white z-10">
                <TableRow>
                  {columns.map((column) => (
                    <TableHead key={column} className="text-xs whitespace-nowrap">
                      {column}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {value.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column) => (
                      <TableCell key={column} className="text-xs whitespace-nowrap">
                        {typeof row[column] === 'object' 
                          ? JSON.stringify(row[column]) 
                          : String(row[column])}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </ScrollArea>
      </div>
    );
  }
  
  // Handle object data with key-value list
  if (typeof value === 'object' && value !== null) {
    return (
      <div className="relative">
        <ScrollArea className="h-[200px] w-[300px]">
          <div style={{ width: 'max-content', minWidth: '100%' }} className="p-2">
            {Object.entries(value).map(([key, val]) => (
              <div key={key} className="text-xs flex">
                <span className="font-medium text-gray-700 min-w-[100px]">{key}:</span>
                <span className="text-gray-600">
                  {typeof val === 'object' 
                    ? JSON.stringify(val, null, 2)
                    : String(val)}
                </span>
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    );
  }
  
  // Handle primitive values
  return (
    <div className="p-2 text-xs">
      {String(value)}
    </div>
  );
};

export default StructuredResultView; 