import { 
  Instagram, Facebook, Twitter, Linkedin, MessageSquare, Gamepad2, Mail,
  Cloud, Database, Key, CreditCard, FolderOpen, Server, MessageCircle,
  Building2, ClipboardList, Settings, LucideIcon
} from "lucide-react";
import { CredentialType } from "@/interface/workflow";

type CredentialIconMap = {
  [K in CredentialType]: LucideIcon;
};

export const CREDENTIAL_ICONS: Partial<CredentialIconMap> = {
  [CredentialType.INSTAGRAM]: Instagram,
  [CredentialType.FACEBOOK]: Facebook,
  [CredentialType.TWITTER]: Twitter,
  [CredentialType.LINKEDIN]: Linkedin,
  [CredentialType.SLACK]: MessageSquare,
  [CredentialType.TWO_FA]: MessageSquare,
  [CredentialType.DISCORD]: Gamepad2,
  [CredentialType.EMAIL_SMTP]: Mail,
  [CredentialType.AWS]: Cloud,
  [CredentialType.GOOGLE_CLOUD]: Cloud,
  [CredentialType.AZURE]: Cloud,
  [CredentialType.OAUTH2]: Key,
  [CredentialType.API_KEY]: Key,
  [CredentialType.BASIC_AUTH]: Key,
  [CredentialType.JWT]: Key,
  [CredentialType.STRIPE]: CreditCard,
  [CredentialType.PAYPAL]: CreditCard,
  [CredentialType.S3]: FolderOpen,
  [CredentialType.FTP]: FolderOpen,
  [CredentialType.SFTP]: FolderOpen,
  [CredentialType.POSTGRES]: Database,
  [CredentialType.MYSQL]: Database,
  [CredentialType.MONGODB]: Database,
  [CredentialType.TWILIO]: MessageCircle,
  [CredentialType.SENDGRID]: MessageCircle,
  [CredentialType.MAILCHIMP]: MessageCircle,
  [CredentialType.SALESFORCE]: Building2,
  [CredentialType.HUBSPOT]: Building2,
  [CredentialType.JIRA]: ClipboardList,
  [CredentialType.ASANA]: ClipboardList,
  [CredentialType.TRELLO]: ClipboardList,
  [CredentialType.CUSTOM]: Settings,
  [CredentialType.API_CREDENTIAL]: Settings,

};

export const CREDENTIAL_CATEGORIES = {
  "Social Media & Communication": [
    CredentialType.INSTAGRAM,
    CredentialType.FACEBOOK,
    CredentialType.TWITTER,
    CredentialType.LINKEDIN,
    CredentialType.SLACK,
    CredentialType.DISCORD,
    CredentialType.EMAIL_SMTP,
    CredentialType.TWO_FA,
  ],
  "Cloud Services": [
    CredentialType.AWS,
    CredentialType.GOOGLE_CLOUD,
    CredentialType.AZURE,
  ],
  "Authentication": [
    CredentialType.OAUTH2,
    CredentialType.API_KEY,
    CredentialType.BASIC_AUTH,
    CredentialType.JWT,
  ],
  "Payment": [
    CredentialType.STRIPE,
    CredentialType.PAYPAL,
  ],
  "Storage": [
    CredentialType.S3,
    CredentialType.FTP,
    CredentialType.SFTP,
  ],
  "Database": [
    CredentialType.POSTGRES,
    CredentialType.MYSQL,
    CredentialType.MONGODB,
  ],
  "Messaging": [
    CredentialType.TWILIO,
    CredentialType.SENDGRID,
    CredentialType.MAILCHIMP,
  ],
  "CRM": [
    CredentialType.SALESFORCE,
    CredentialType.HUBSPOT,
  ],
  "Project Management": [
    CredentialType.JIRA,
    CredentialType.ASANA,
    CredentialType.TRELLO,
  ],
  "Generic/Custom": [
    CredentialType.CUSTOM,
    CredentialType.API_CREDENTIAL,
  ],
}; 