import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Plus, Copy, Upload } from "lucide-react";
import { get, del, post, put } from "@/lib/rest";
import { toast } from "../../components/ui/use-toast";
import { IWorkflow, IWorkflowCredential, CredentialType } from "@/interface/workflow";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { 
  Instagram, Facebook, Twitter, Linkedin, MessageSquare, Gamepad2, Mail,
  Cloud, Database, Key, CreditCard, FolderOpen, Server, MessageCircle,
  Building2, ClipboardList, Settings, LucideIcon
} from "lucide-react";
import { CredentialDialog } from "./CredentialDialog";
import { ImportWorkflowDialog } from "./ImportWorkflowDialog";

type CredentialIconMap = {
  [K in CredentialType]: LucideIcon;
};

const CREDENTIAL_ICONS: Partial<CredentialIconMap> = {
  [CredentialType.INSTAGRAM]: Instagram,
  [CredentialType.FACEBOOK]: Facebook,
  [CredentialType.TWITTER]: Twitter,
  [CredentialType.LINKEDIN]: Linkedin,
  [CredentialType.SLACK]: MessageSquare,
  [CredentialType.DISCORD]: Gamepad2,
  [CredentialType.EMAIL_SMTP]: Mail,
  [CredentialType.AWS]: Cloud,
  [CredentialType.GOOGLE_CLOUD]: Cloud,
  [CredentialType.AZURE]: Cloud,
  [CredentialType.OAUTH2]: Key,
  [CredentialType.API_KEY]: Key,
  [CredentialType.BASIC_AUTH]: Key,
  [CredentialType.JWT]: Key,
  [CredentialType.STRIPE]: CreditCard,
  [CredentialType.PAYPAL]: CreditCard,
  [CredentialType.S3]: FolderOpen,
  [CredentialType.FTP]: FolderOpen,
  [CredentialType.SFTP]: FolderOpen,
  [CredentialType.POSTGRES]: Database,
  [CredentialType.MYSQL]: Database,
  [CredentialType.MONGODB]: Database,
  [CredentialType.TWILIO]: MessageCircle,
  [CredentialType.SENDGRID]: MessageCircle,
  [CredentialType.MAILCHIMP]: MessageCircle,
  [CredentialType.SALESFORCE]: Building2,
  [CredentialType.HUBSPOT]: Building2,
  [CredentialType.JIRA]: ClipboardList,
  [CredentialType.ASANA]: ClipboardList,
  [CredentialType.TRELLO]: ClipboardList,
  [CredentialType.CUSTOM]: Settings,
  [CredentialType.API_CREDENTIAL]: Settings,
};

const CREDENTIAL_CATEGORIES = {
  "Social Media & Communication": [
    CredentialType.INSTAGRAM,
    CredentialType.FACEBOOK,
    CredentialType.TWITTER,
    CredentialType.LINKEDIN,
    CredentialType.SLACK,
    CredentialType.DISCORD,
    CredentialType.EMAIL_SMTP,
  ],
  "Cloud Services": [
    CredentialType.AWS,
    CredentialType.GOOGLE_CLOUD,
    CredentialType.AZURE,
  ],
  "Authentication": [
    CredentialType.OAUTH2,
    CredentialType.API_KEY,
    CredentialType.BASIC_AUTH,
    CredentialType.JWT,
  ],
  "Payment": [
    CredentialType.STRIPE,
    CredentialType.PAYPAL,
  ],
  "Storage": [
    CredentialType.S3,
    CredentialType.FTP,
    CredentialType.SFTP,
  ],
  "Database": [
    CredentialType.POSTGRES,
    CredentialType.MYSQL,
    CredentialType.MONGODB,
  ],
  "Messaging": [
    CredentialType.TWILIO,
    CredentialType.SENDGRID,
    CredentialType.MAILCHIMP,
  ],
  "CRM": [
    CredentialType.SALESFORCE,
    CredentialType.HUBSPOT,
  ],
  "Project Management": [
    CredentialType.JIRA,
    CredentialType.ASANA,
    CredentialType.TRELLO,
  ],
  "Generic/Custom": [
    CredentialType.CUSTOM,
    CredentialType.API_CREDENTIAL,
  ],
};

const copyToClipboard = async (workflow: IWorkflow) => {
  const workflowCopy = {
    name: workflow.name,
    nodes: workflow.nodes,
    connections: workflow.connections,
    settings: workflow.settings,
    active: workflow.active,
  };
  
  try {
    await navigator.clipboard.writeText(JSON.stringify(workflowCopy, null, 2));
    toast({
      title: "Workflow configuration copied to clipboard",
      description: "You can now paste and import this configuration in another project",
    });
  } catch (error) {
    toast({
      variant: "destructive",
      title: "Failed to copy workflow configuration",
      description: "Please try again",
    });
  }
};

export default function Workflow() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [allWorkflows, setAllWorkflows] = useState<IWorkflow[]>([]);
  const [allCredentials, setAllCredentials] = useState<IWorkflowCredential[]>([]);
  const [isCredentialDialogOpen, setIsCredentialDialogOpen] = useState(false);
  const [newCredential, setNewCredential] = useState<Partial<IWorkflowCredential>>({
    name: '',
    type: undefined,
    data: {}
  });
  const [editingCredential, setEditingCredential] = useState<IWorkflowCredential | null>(null);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  // Derived states
  const workflows = allWorkflows.filter((w: any) => w.environment?.name === 'dev');
  const prodWorkflows = allWorkflows.filter((w: any) => w.environment?.name === 'production');
  const credentials = allCredentials.filter((c: any) => c.environment?.name === 'dev');
  const prodCredentials = allCredentials.filter((c: any) => c.environment?.name === 'production');

  useEffect(() => {
    fetchWorkflows();
    fetchCredentials();
  }, []);

  const fetchWorkflows = async () => {
    try {
      const response = await get({ url: `/api/project/${projectId}/workflow` });
      const fetchedWorkflows = response?.data?.workflows || [];
      setAllWorkflows(fetchedWorkflows);
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error fetching workflows",
        description: "There was an error fetching workflows",
      });
    }
  };

  const handleDelete = async (workflowId: string) => {
    try {
      await del({
        url: `/api/project/${projectId}/workflow/${workflowId}`,
        data: {},
      });
      fetchWorkflows();
      toast({
        title: "Workflow deleted successfully",
        description: "The workflow has been deleted successfully",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error deleting workflow",
        description: "There was an error deleting the workflow",
      });
    }
  };

  const createNewWorkflow = () => {
    navigate(`/project/${projectId}/workflow/edit`);
  };

  const fetchCredentials = async () => {
    try {
      const response = await get({ url: `/api/project/${projectId}/credential` });
      setAllCredentials(response?.data?.credentials || []);
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error fetching credentials",
      });
    }
  };

  const handleCredentialDelete = async (credentialId: string) => {
    try {
      await del({
        url: `/api/project/${projectId}/credential/${credentialId}`,
        data: {},
      });
      fetchCredentials();
      toast({
        title: "Credential deleted successfully",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error deleting credential",
      });
    }
  };

  const handleCredentialEdit = (credential: IWorkflowCredential) => {
    setEditingCredential(credential);
    setIsCredentialDialogOpen(true);
  };

  const handleCredentialSave = async () => {
    try {
      const isEditing = !!editingCredential;
      const url = isEditing 
        ? `/api/project/${projectId}/credential/${editingCredential.id}`
        : `/api/project/${projectId}/credential`;

      if (isEditing) {
        await put({ url, data: editingCredential });
      } else {
        await post({ url, data: newCredential });
      }
      
      setIsCredentialDialogOpen(false);
      setEditingCredential(null);
      setNewCredential({ name: '', type: undefined, data: {} });
      fetchCredentials();
      
      toast({
        title: `Credential ${isEditing ? 'updated' : 'saved'} successfully`,
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: `Error ${editingCredential ? 'updating' : 'saving'} credential`,
      });
    }
  };

  const handleImport = async (configString: string) => {
    try {
      const workflowConfig = JSON.parse(configString);

      if (!workflowConfig.name || !workflowConfig.nodes || !workflowConfig.connections) {
        throw new Error("Invalid workflow configuration");
      }

      const response = await post({
        url: `/api/project/${projectId}/workflow`,
        data: {
          name: workflowConfig.name,
          nodes: JSON.stringify(workflowConfig.nodes),
          connections: JSON.stringify(workflowConfig.connections),
          settings: JSON.stringify(workflowConfig.settings || {}),
          active: workflowConfig.active || false,
        },
      });

      fetchWorkflows();
      toast({
        title: "Workflow imported successfully",
        description: "The workflow has been created from the imported configuration",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Failed to import workflow",
        description: "Please check if the configuration file is valid",
      });
    }
  };

  const getWorkflowSyncStatus = (workflow: IWorkflow) => {
    const prodWorkflow = prodWorkflows.find(pw => pw.name === workflow.name);
    if (!prodWorkflow) return "Not in Production";
    
    const devConfig = {
      nodes: workflow.nodes,
      connections: workflow.connections,
      settings: workflow.settings,
      active: workflow.active
    };
    const prodConfig = {
      nodes: prodWorkflow.nodes,
      connections: prodWorkflow.connections,
      settings: prodWorkflow.settings,
      active: prodWorkflow.active
    };
    
    return JSON.stringify(devConfig) === JSON.stringify(prodConfig) ? "In sync" : "Out of sync";
  };

  const getCredentialSyncStatus = (credential: IWorkflowCredential) => {
    const prodCredential = prodCredentials.find(pc => pc.name === credential.name);
    if (!prodCredential) return "Not in Production";

    return JSON.stringify(credential.data) === JSON.stringify(prodCredential.data) ? "In sync" : "Out of sync";
  };

  const getSyncStatusStyle = (status: string) => {
    if (status === "In sync") {
      return "bg-green-900 text-green-400";
    } else {
      return "bg-red-900 text-red-400";
    }
  };

  return (
    <div className="h-full">
      <div className="container mx-auto p-4">
        <Tabs defaultValue="workflows">
          <TabsList>
            <TabsTrigger value="workflows">Workflows</TabsTrigger>
            <TabsTrigger value="credentials">Credentials</TabsTrigger>
          </TabsList>

          <TabsContent value="workflows">
            <div className="mb-6 flex items-center justify-between">
              <h2 className="text-2xl font-bold">Workflows</h2>
              <div className="flex items-center gap-2">
                <Button 
                  variant="outline" 
                  className="flex items-center gap-2"
                  onClick={() => setIsImportDialogOpen(true)}
                >
                  <Upload className="h-4 w-4" />
                  Import
                </Button>
                <Button onClick={createNewWorkflow} className="flex items-center gap-2">
                  <Plus className="h-4 w-4" />
                  Create Workflow
                </Button>
              </div>
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="text-left">Name</TableHead>
                  <TableHead className="text-left">Active</TableHead>
                  <TableHead className="text-left">Status</TableHead>
                  <TableHead className="text-left">Created At</TableHead>
                  <TableHead className="text-left">Last Updated</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {workflows.map((workflow) => (
                  <TableRow
                    key={workflow.id}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/project/${projectId}/workflow/edit/${workflow.id}`)
                    }
                  >
                    <TableCell className="text-left">{workflow.name}</TableCell>
                    <TableCell className="text-left">{workflow.active ? "Yes" : "No"}</TableCell>
                    <TableCell className="text-left">
                      <span className={`inline-flex rounded-full px-2 py-1 text-xs font-semibold ${getSyncStatusStyle(getWorkflowSyncStatus(workflow))}`}>
                        {getWorkflowSyncStatus(workflow)}
                      </span>
                    </TableCell>
                    <TableCell className="text-left">
                      {new Date(workflow.created_at || "").toLocaleDateString()}
                    </TableCell>
                    <TableCell className="text-left">
                      {new Date(workflow.updated_at || "").toLocaleDateString()}
                    </TableCell>
                    <TableCell className="text-right">
                      <div className="flex items-center justify-end gap-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(workflow);
                          }}
                        >
                          <Copy className="h-4 w-4" />
                        </Button>
                        <Button
                          variant="destructive"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(workflow?.id || "");
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabsContent>

          <TabsContent value="credentials">
            <div className="mb-6 flex items-center justify-between">
              <h2 className="text-2xl font-bold">Credentials</h2>
              <Dialog 
                open={isCredentialDialogOpen} 
                onOpenChange={(open) => {
                  setIsCredentialDialogOpen(open);
                  if (!open) {
                    setEditingCredential(null);
                    setNewCredential({ name: '', type: undefined, data: {} });
                  }
                }}
              >
                <DialogTrigger asChild>
                  <Button className="flex items-center gap-2">
                    <Plus className="h-4 w-4" />
                    Add Credential
                  </Button>
                </DialogTrigger>
                <CredentialDialog 
                  credential={editingCredential || newCredential}
                  onCredentialChange={(cred) => {
                    if (editingCredential) {
                      setEditingCredential(cred as IWorkflowCredential);
                    } else {
                      setNewCredential(cred);
                    }
                  }}
                  onSave={handleCredentialSave}
                  mode={editingCredential ? 'edit' : 'create'}
                />
              </Dialog>
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="text-left">Name</TableHead>
                  <TableHead className="text-left">Type</TableHead>
                  <TableHead className="text-left">Status</TableHead>
                  <TableHead className="text-left">Created At</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {credentials.map((credential) => (
                  <TableRow 
                    key={credential.id} 
                    className="cursor-pointer"
                    onClick={() => handleCredentialEdit(credential)}
                  >
                    <TableCell className="text-left">{credential.name}</TableCell>
                    <TableCell className="text-left">
                      <div className="flex items-center gap-2">
                        {CREDENTIAL_ICONS[credential.type] && 
                          React.createElement(CREDENTIAL_ICONS[credential.type]!, { size: 16 })}
                        <span>{credential.type}</span>
                      </div>
                    </TableCell>
                    <TableCell className="text-left">
                      <span className={`inline-flex rounded-full px-2 py-1 text-xs font-semibold ${getSyncStatusStyle(getCredentialSyncStatus(credential))}`}>
                        {getCredentialSyncStatus(credential)}
                      </span>
                    </TableCell>
                    <TableCell className="text-left">
                      {new Date(credential.created_at || "").toLocaleDateString()}
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCredentialDelete(credential.id);
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TabsContent>
        </Tabs>
      </div>
      <ImportWorkflowDialog 
        isOpen={isImportDialogOpen}
        onClose={() => setIsImportDialogOpen(false)}
        onImport={handleImport}
      />
    </div>
  );
}
