import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { del, post, put } from "@/lib/rest";
import { useUserStore } from "@/lib/store";

const ConstantModal = ({
  constant,
  closeAction,
  isEditMode = false,
  environments,
  projectId,
}: {
  constant: any;
  closeAction: () => void;
  isEditMode?: boolean;
  environments: any;
  projectId: any;
}) => {
  const [selectedConstant, setSelectedConstant] = useState(constant);
  const currentUser: any = useUserStore((state: any) => state.user);

  const onClickCreate = async () => {
    try {
      await post({
        url: `/api/project/${projectId}/constant`,
        data: {
          key: selectedConstant.key,
          value: selectedConstant.value,
          environment: selectedConstant.environment_id,
        },
      });
      closeAction();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickUpdate = async () => {
    try {
      await put({
        url: `/api/project/${projectId}/constant/${selectedConstant.id}`,
        data: {
          key: selectedConstant.key,
          value: selectedConstant.value,
          environment: selectedConstant.environment_id,
        },
      });
      closeAction();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickDelete = async () => {
    try {
      await del({
        url: `/api/project/${projectId}/constant/${selectedConstant.id}`,
        data: {},
      });
      closeAction();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="absolute left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-slate-950 bg-opacity-70 backdrop-blur-sm">
      <Card className="h-fit max-h-[80%] w-fit min-w-[40rem] overflow-y-auto">
        <CardHeader className="flex w-full justify-start">
          <CardTitle className="w-fit">
            {isEditMode ? "Edit" : "Create"} Constant
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-2 flex flex-col space-y-2">
              <Label className="w-fit">Key</Label>
              <Input
                type="text"
                id="key"
                placeholder="constant key"
                disabled={
                  selectedConstant.environment_id ===
                    environments.find((env: any) => env.name === "production")
                      ?.id && currentUser.role !== "admin"
                }
                value={selectedConstant.key || ""}
                onChange={e =>
                  setSelectedConstant({
                    ...selectedConstant,
                    key: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-span-2 flex flex-col space-y-2">
              <Label className="w-fit">Value</Label>
              <Input
                type="text"
                id="value"
                placeholder="constant value"
                disabled={
                  selectedConstant.environment_id ===
                    environments.find((env: any) => env.name === "production")
                      ?.id && currentUser.role !== "admin"
                }
                value={selectedConstant.value || ""}
                onChange={e =>
                  setSelectedConstant({
                    ...selectedConstant,
                    value: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-span-4 flex flex-col space-y-2">
              <Label className="w-fit">Environment</Label>
              <Select
                value={selectedConstant.environment_id || ""}
                disabled={
                  selectedConstant.environment_id ===
                    environments.find((env: any) => env.name === "production")
                      ?.id && currentUser.role !== "admin"
                }
                onValueChange={(value: any) => {
                  setSelectedConstant({
                    ...selectedConstant,
                    environment_id: value,
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="ENV" />
                </SelectTrigger>
                <SelectContent>
                  {!isEditMode && currentUser.role !== "admin"
                    ? environments
                        .filter((env: any) => env.name !== "production")
                        .map((env: any) => (
                          <SelectGroup key={env.id}>
                            <SelectItem value={env.id}>{env.name}</SelectItem>
                          </SelectGroup>
                        ))
                    : environments.map((env: any) => (
                        <SelectGroup key={env.id}>
                          <SelectItem value={env.id}>{env.name}</SelectItem>
                        </SelectGroup>
                      ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex justify-between space-x-2 pt-4">
          <div className="flex">
            {!(
              selectedConstant.environment_id ===
                environments.find((env: any) => env.name === "production")
                  ?.id && currentUser.role !== "admin"
            ) && (
              <Button
                className="w-fit"
                variant="destructive"
                onClick={onClickDelete}
              >
                Delete
              </Button>
            )}
          </div>
          <div className="flex">
            <Button className="w-fit" variant="outline" onClick={closeAction}>
              Cancel
            </Button>
            {!(
              selectedConstant.environment_id ===
                environments.find((env: any) => env.name === "production")
                  ?.id && currentUser.role !== "admin"
            ) && (
              <Button
                className="ml-4 w-fit"
                onClick={isEditMode ? onClickUpdate : onClickCreate}
              >
                {isEditMode ? "Save" : "Create"}
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ConstantModal; 