import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import Editor from "@monaco-editor/react";

interface ImportWorkflowDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onImport: (config: string) => void;
}

export function ImportWorkflowDialog({ isOpen, onClose, onImport }: ImportWorkflowDialogProps) {
  const [editorContent, setEditorContent] = useState('{\n  "name": "",\n  "nodes": [],\n  "connections": [],\n  "settings": {},\n  "active": false\n}');

  const handleImport = () => {
    onImport(editorContent);
    onClose();
    setEditorContent('{\n  "name": "",\n  "nodes": [],\n  "connections": [],\n  "settings": {},\n  "active": false\n}');
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px] sm:h-[600px]">
        <DialogHeader>
          <DialogTitle>Import Workflow Configuration</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col h-full gap-4">
          <div className="flex-grow">
            <Editor
              height="400px"
              defaultLanguage="json"
              value={editorContent}
              onChange={(value) => setEditorContent(value || '')}
              options={{
                minimap: { enabled: false },
                formatOnPaste: true,
                formatOnType: true,
              }}
            />
          </div>
          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleImport}>
              Import
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
} 