import { Editor } from "@monaco-editor/react";
import { BaseInputProps } from "./types";
import { useState } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Expand } from "lucide-react";

export const CodeEditor: React.FC<BaseInputProps> = ({ value, onChange, schema }) => {
  const { language = "javascript", height = "200px" } = schema.ui || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => setIsModalOpen(true);

  return (
    <div className="relative">
      <div className="absolute right-2 top-[-30px] z-10">
        <Button
          onClick={handleModalOpen}
          size="icon"
          variant="ghost"
          className="h-8 w-8 bg-background/95 hover:bg-accent"
        >
          <Expand className="h-4 w-4" />
        </Button>
      </div>

      <Editor
        height={height}
        defaultLanguage={language}
        value={value || ""}
        onChange={(value) => onChange(value)}
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          fontSize: 14,
          lineNumbers: "off",
          glyphMargin: false,   // Removes the glyph margin
          folding: false,       // Disables the folding controls
          lineDecorationsWidth: 0,  // Removes extra spacing
          lineNumbersMinChars: 0    // Minimizes the space taken by line numbers
        }}
        className="border border-input rounded-md [&_.monaco-editor-background]:!bg-transparent [&_.monaco-editor]:!outline-none [&_.monaco-editor]:!border-none"
      />

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="max-w-[90vw]">
          <Editor
            height="80vh"
            defaultLanguage={language}
            value={value || ""}
            onChange={(value) => onChange(value)}
            options={{
              minimap: { enabled: true },
              scrollBeyondLastLine: false,
              fontSize: 14,
              wordWrap: "on"
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}; 