import { DatabaseConfig } from "@/interface/config";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type ExecutionResults = Record<string, Record<string, any>>

export interface WorkflowExecutionState {
  results: {
    [workflowId: string]: {
      [nodeId: string]: any;
    };
  };
  setWorkflowResults: (workflowId: string, results: any) => void;
  clearWorkflowResults: (workflowId: string) => void;
  clearAllResults: () => void
}

export const useConfigStore = create(
  devtools(set => ({
    config: {
      version: "v1",
      database: "sqlite3",
      tables: [],
    },
    oldConfig: {
      version: "v1",
      database: "sqlite3",
      tables: [],
    },
    project: {},
    setConfig: (newConfig: DatabaseConfig) =>
      set(() => ({ config: newConfig })),
    setOldConfig: (oldConfig: DatabaseConfig) =>
      set(() => ({ oldConfig: oldConfig })),
    setProdConfig: (prodConfig: DatabaseConfig) =>
      set(() => ({ prodConfig: prodConfig })),
    setProject: (project: any) => set(() => ({ project: project })),
  })),
);

export const useUserStore = create(
  devtools(set => ({
    user: {},
    setUser: (user: any) => set(() => ({ user: user })),
  })),
);

export const useWorkflowExecutionStore = create<WorkflowExecutionState>((set) => ({
  results: {},
  setWorkflowResults: (workflowId, results) =>
    set((state) => ({
      results: {
        ...state.results,
        [workflowId]: results,
      },
    })),
  clearWorkflowResults: (workflowId) =>
    set((state) => {
      const newResults = { ...state.results };
      delete newResults[workflowId];
      return { results: newResults };
    }),
  clearAllResults: () => set({ results: {} })
}));
