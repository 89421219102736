import React, { memo } from "react";
import { Handle, Position, Node } from "@xyflow/react";
import { IWorkflowNodeDefinition } from "@/interface/workflow";
import { useWorkflowExecutionStore } from "@/lib/store";
import ResultViewer from "./ResultViewer";
import {
  BotMessageSquare,
  CodeXml,
  Database,
  Merge,
  Mail,
  Globe,
  Slack,
  Zap,
  Terminal,
} from "lucide-react"; // Import the icons

type ICustomNodeProps = Node<{
  definition: IWorkflowNodeDefinition;
  label: string;
}>;

const CustomNode = memo(({ data, id }: ICustomNodeProps) => {
  const { definition } = data;
  const { connection_config } = definition;

  const nodeResults = useWorkflowExecutionStore(state => {
    const workflowId = window.location.pathname.split("/").pop() || "";
    return state.results[workflowId]?.[data.label];
  });

  const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevents React Flow from capturing scroll events
  };

  const getIcon = (name: string) => {
    switch (name) {
      case "chatgpt":
        return (
          <BotMessageSquare
            size={16}
            className="absolute right-2 top-2 text-slate-500"
          />
        );
      case "javascript":
        return (
          <CodeXml
            size={16}
            className="absolute right-2 top-2 text-slate-500"
          />
        );
      case "sql-query":
        return (
          <Terminal
            size={16}
            className="absolute right-2 top-2 text-slate-500"
          />
        );
      case "merge":
        return (
          <Merge size={16} className="absolute right-2 top-2 text-slate-500" />
        );
      case "email":
        return (
          <Mail size={16} className="absolute right-2 top-2 text-slate-500" />
        );
      case "http_request":
        return (
          <Globe size={16} className="absolute right-2 top-2 text-slate-500" />
        );
      case "slack":
        return (
          <Slack size={16} className="absolute right-2 top-2 text-slate-500" />
        );
      case "database":
        return (
          <Database
            size={16}
            className="absolute right-2 top-2 text-slate-500"
          />
        );
      case "conditional":
        return (
          <Zap size={16} className="absolute right-2 top-2 text-slate-500" />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* Input handles descriptions - outside the node */}
      <div className="absolute left-0 top-0 flex h-[64px] -translate-x-[calc(100%+8px)] flex-col justify-center gap-4">
        {connection_config.inputs.types.map((type: string) => (
          <div key={`input-label-${type}`} className="text-xs text-gray-500">
            {type}
          </div>
        ))}
      </div>

      <div className="flex flex-col">
        {/* Main node body with fixed height */}
        <div className="relative h-[64px] min-w-[320px] rounded-md border-2 border-gray-200 bg-white shadow-md">
          {/* Input handles only */}
          <div className="absolute left-0 top-0 flex h-full flex-col justify-center gap-4">
            {connection_config.inputs.types.map((type: string) => (
              <Handle
                key={`input-${type}`}
                type="target"
                position={Position.Left}
                id={type}
                style={{
                  background: "#555",
                  width: 8,
                  height: 8,
                }}
              />
            ))}
          </div>

          {/* Node content */}
          <div className="px-4 py-2">
            <div className="font-bold">{data.label}</div>
            {getIcon(definition.name)}
          </div>

          {/* Output handles only */}
          <div className="absolute right-0 top-0 flex h-full flex-col justify-center gap-4">
            {connection_config.outputs.types.map((type: string) => (
              <Handle
                key={`output-${type}`}
                type="source"
                position={Position.Right}
                id={type}
                style={{
                  background: "#555",
                  width: 8,
                  height: 8,
                }}
              />
            ))}
          </div>
        </div>

        {/* Results section below the main node */}
        {nodeResults && (
          <div
            onWheel={handleScroll}
            className="mt-2 min-w-[320px] rounded-md border-2 border-gray-200 bg-white shadow-md"
          >
            <div className="px-4 py-2">
              <div className="mb-1 text-xs font-semibold text-gray-600">
                Result:
              </div>
              <div className="overflow-hidden rounded border border-gray-200">
                <ResultViewer value={nodeResults} />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Output handles descriptions - outside the node */}
      <div className="absolute right-0 top-0 flex h-[64px] translate-x-[calc(100%+8px)] flex-col justify-center gap-4">
        {connection_config.outputs.types.map((type: string) => (
          <div key={`output-label-${type}`} className="text-xs text-gray-500">
            {type}
          </div>
        ))}
      </div>
    </>
  );
});

export default CustomNode;
