import { Input } from "@/components/ui/input";
import { BaseInputProps } from "./types";

export const TextInput: React.FC<BaseInputProps> = ({ value, onChange, schema }) => {
  return (
    <Input
      type="text"
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      placeholder={schema.ui?.placeholder}
      className="w-full"
    />
  );
}; 