import React, { useState, useCallback, useEffect } from "react";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";

import {
  ReactFlow,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Connection,
  addEdge,
  ReactFlowProvider,
  NodeTypes,
  Edge,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import NodePanel from "./NodePanel";
import ConfigPanel from "./ConfigPanel";
import CustomNode from "./CustomNode";
import {
  IWorkflowConnection,
  IWorkflowNode,
  IWorkflowNodeDefinition,
  IWorkflowSettings,
} from "../../interface/workflow";
import { useParams } from "react-router-dom";
import { get, post, del } from "@/lib/rest";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { Loader, PlayIcon } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { useWorkflowExecutionStore, WorkflowExecutionState } from "@/lib/store";

const nodeTypes: NodeTypes = {
  custom: CustomNode as any,
};

const defaultWorkflowSettings: IWorkflowSettings = {
  errorHandling: {
    retryCount: 0,
    retryDelay: 0,
    failureAction: 'stop'
  },
  execution: {
    timeout: 0,
    concurrency: 1
  },
  access: {
    execute: ''
  }
};

const WorkflowEditor: React.FC = () => {
  const { projectId, workflowId } = useParams();
  const [nodes, setNodes, onNodesChange] = useNodesState<IWorkflowNode>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<IWorkflowConnection>(
    [],
  );
  const [nodeDefinitions, setNodeDefinitions] = useState<
    Record<string, IWorkflowNodeDefinition[]>
  >({});
  const [selectedNode, setSelectedNode] = useState<IWorkflowNode | null>(null);
  const [selectedEdge, setSelectedEdge] = useState<IWorkflowConnection | null>(
    null,
  );
  const [workflowName, setWorkflowName] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isExecuting, setIsExecuting] = useState(false);
  const [workflowSettings, setWorkflowSettings] = useState<IWorkflowSettings>(defaultWorkflowSettings);

  const clearWorkflowResults = useWorkflowExecutionStore(
    (state: WorkflowExecutionState) => state.clearWorkflowResults,
  );
  const setWorkflowResults = useWorkflowExecutionStore(
    (state: WorkflowExecutionState) => state.setWorkflowResults,
  );

  useEffect(() => {
    const fetchNodeDefinitions = async () => {
      try {
        const response = await get({
          url: `/api/project/${projectId}/workflow/nodes`,
        });
        setNodeDefinitions(response.data.nodeDefinitions);
      } catch (error) {
        console.error("Error fetching node definitions:", error);
      }
    };
    fetchNodeDefinitions();
  }, [projectId]);

  useEffect(() => {
    const fetchWorkflow = async () => {
      if (!workflowId) return;
      try {
        const response = await get({
          url: `/api/project/${projectId}/workflow/${workflowId}`,
        });
        setNodes(response.data.workflow.nodes || []);
        setEdges(response.data.workflow.connections || []);
        setWorkflowName(response.data.workflow.name);
        setIsActive(response.data.workflow.active || false);
        setWorkflowSettings(response.data.workflow.settings.access ? response.data.workflow.settings : defaultWorkflowSettings);
      } catch (error) {
        console.error("Error fetching workflow:", error);
        toast({
          variant: "destructive",
          title: "Failed to load workflow",
        });
      }
    };
    fetchWorkflow();
  }, [workflowId, projectId]);

  const onConnect = useCallback(
    (connection: Connection) => setEdges(eds => addEdge(connection, eds)),
    [setEdges],
  );

  const onNodeClick = useCallback(
    (event: React.MouseEvent, node: IWorkflowNode) => {
      setSelectedNode(node);
    },
    [],
  );

  const onEdgeClick = useCallback((event: React.MouseEvent, edge: Edge) => {
    setSelectedEdge(edge as IWorkflowConnection);
  }, []);

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      const nodeType = event.dataTransfer.getData("application/reactflow");
      const definition = Object.values(nodeDefinitions)
        .flat()
        .find(def => def.name === nodeType);

      if (!definition) return;

      const reactFlowBounds = document
        .querySelector(".react-flow")
        ?.getBoundingClientRect();
      const position = {
        x: event.clientX - (reactFlowBounds?.left ?? 0),
        y: event.clientY - (reactFlowBounds?.top ?? 0),
      };

      const newNode = {
        id: `${definition.name}-${Date.now()}`,
        type: "custom",
        position,
        data: {
          definition,
          active: true,
          parameters: {},
          label: definition.display_name,
          isDisabled: false,
        },
      } as IWorkflowNode;

      setNodes(nds => [...nds, newNode]);
    },
    [nodeDefinitions, setNodes],
  );

  const updateNodeParameters = useCallback(
    (nodeId: string, key: string, value: any) => {
      setNodes(nodes =>
        nodes.map(node =>
          node.id === nodeId
            ? {
              ...node,
              data: {
                ...node.data,
                parameters: {
                  ...(node.data.parameters || {}),
                  [key]: value,
                },
              },
            }
            : node,
        ),
      );
      // Update selectedNode to reflect the parameter change immediately
      setSelectedNode(prev =>
        prev?.id === nodeId
          ? {
            ...prev,
            data: {
              ...prev.data,
              parameters: {
                ...(prev.data.parameters || {}),
                [key]: value,
              },
            },
          }
          : prev,
      );
    },
    [setNodes],
  );

  const updateNodeLabel = useCallback(
    (nodeId: string, newLabel: string) => {
      setNodes(nodes =>
        nodes.map(node =>
          node.id === nodeId
            ? {
              ...node,
              data: {
                ...node.data,
                label: newLabel,
              },
            }
            : node,
        ),
      );
      // Update selectedNode to reflect the change immediately
      setSelectedNode(prev =>
        prev?.id === nodeId
          ? {
            ...prev,
            data: {
              ...prev.data,
              label: newLabel,
            },
          }
          : prev,
      );
    },
    [setNodes],
  );

  const deleteNode = useCallback(
    (nodeId: string) => {
      setNodes(nds => nds.filter(node => node.id !== nodeId));
      setEdges(eds =>
        eds.filter(edge => edge.source !== nodeId && edge.target !== nodeId),
      );
      setSelectedNode(null);
    },
    [setNodes, setEdges],
  );

  const deleteEdge = useCallback(() => {
    if (!selectedEdge) return;
    setEdges(eds => eds.filter(edge => edge.id !== selectedEdge.id));
    setSelectedEdge(null);
  }, [selectedEdge, setEdges]);

  const updateNodeCredentials = useCallback(
    (nodeId: string, key: string, value: any) => {
      setNodes(nodes =>
        nodes.map(node =>
          node.id === nodeId
            ? {
              ...node,
              data: {
                ...node.data,
                credentials: {
                  ...(node.data.credentials || {}),
                  [key]: value,
                },
              },
            }
            : node,
        ),
      );
      // Update selectedNode to reflect the credential change immediately
      setSelectedNode(prev =>
        prev?.id === nodeId
          ? {
            ...prev,
            data: {
              ...prev.data,
              credentials: {
                ...(prev.data.credentials || {}),
                [key]: value,
              },
            },
          }
          : prev,
      );
    },
    [setNodes],
  );


  const updateNodeActive = useCallback((nodeId: string, active: boolean) => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, active } }
          : node
      )
    );
    // Update selectedNode to reflect the active state change immediately
    setSelectedNode((prev) =>
      prev?.id === nodeId
        ? { ...prev, data: { ...prev.data, active } }
        : prev
    );
  }, [setNodes]);

  const handleUpdateWorkflowSettings = useCallback((newSettings: Partial<IWorkflowSettings>) => {
    setWorkflowSettings(prev => ({
      ...prev,
      ...newSettings,
      errorHandling: {
        ...prev.errorHandling,
        ...(newSettings.errorHandling || {})
      },
      execution: {
        ...prev.execution,
        ...(newSettings.execution || {})
      },
      access: {
        ...prev.access,
        ...(newSettings.access || {})
      }
    }));
  }, []);

  const saveWorkflow = async () => {
    try {
      const payload = {
        name: workflowName,
        nodes: JSON.stringify(nodes),
        connections: JSON.stringify(edges),
        settings: JSON.stringify(workflowSettings),
        active: isActive,
      };

      let response;
      if (workflowId) {
        // Update existing workflow
        response = await post({
          url: `/api/project/${projectId}/workflow/${workflowId}`,
          data: payload,
        });
      } else {
        // Create new workflow
        response = await post({
          url: `/api/project/${projectId}/workflow`,
          data: payload,
        });
        // Redirect to the newly created workflow
        const newWorkflowId = response.data.workflow.id;
        window.location.href = `/project/${projectId}/workflow/edit/${newWorkflowId}`;
      }

      toast({
        variant: "default",
        title: workflowId
          ? "Workflow saved successfully"
          : "Workflow created successfully",
      });
    } catch (error) {
      console.error("Error saving workflow:", error);
      toast({
        variant: "destructive",
        title: workflowId
          ? "Failed to save workflow"
          : "Failed to create workflow",
      });
    }
  };

  const deleteWorkflow = async () => {
    if (
      !workflowId ||
      !window.confirm("Are you sure you want to delete this workflow?")
    )
      return;

    try {
      await del({
        url: `/api/project/${projectId}/workflow/${workflowId}`,
        data: {},
      });
      toast({
        variant: "default",
        title: "Workflow deleted successfully",
      });
      // Navigate back to workflows list
      window.location.href = `/project/${projectId}/workflow`;
    } catch (error) {
      console.error("Error deleting workflow:", error);
      toast({
        variant: "destructive",
        title: "Failed to delete workflow",
      });
    }
  };

  const executeWorkflow = async () => {
    if (!workflowId) return;

    try {
      setIsExecuting(true);
      clearWorkflowResults(workflowId);

      const response = await post({
        url: `/api/project/${projectId}/workflow/${workflowId}/execute`,
        data: {
          nodes: nodes,
          edges: edges,
        },
      });

      const executionResults = response.data.result || {};
      setWorkflowResults(workflowId, executionResults);

      toast({
        variant: "default",
        title: "Workflow execution completed",
      });
    } catch (error) {
      console.error("Error executing workflow:", error);
      toast({
        variant: "destructive",
        title: "Failed to execute workflow",
      });
    } finally {
      setIsExecuting(false);
    }
  };

  return (
    <ResizablePanelGroup direction={"horizontal"}>
      <div className="flex h-screen w-screen">
        <ResizablePanel defaultSize={75}>
          <NodePanel nodeDefinitions={nodeDefinitions} />
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel defaultSize={250}>
          <div className="flex h-[calc(100vh-60px)] flex-grow flex-col">
            <div className="flex items-center gap-4 border-b p-4">
              <Input
                placeholder="Workflow name"
                value={workflowName}
                onChange={e => setWorkflowName(e.target.value)}
                className="max-w-xs"
              />
              <div className="flex items-center space-x-2">
                <Switch
                  id="workflow-active"
                  checked={isActive}
                  onCheckedChange={setIsActive}
                />
                <Label htmlFor="workflow-active">Active</Label>
              </div>
              <Button onClick={saveWorkflow}>Save Workflow</Button>
              {workflowId && (
                <>
                  <Button variant="destructive" onClick={deleteWorkflow}>
                    Delete Workflow
                  </Button>
                  {isActive && (
                    <Button
                      variant="outline"
                      onClick={executeWorkflow}
                      className="gap-2"
                      disabled={isExecuting}
                    >
                      {isExecuting ? (
                        <>
                          <span className="animate-spin">
                            <Loader size={16} />
                          </span>
                          Executing...
                        </>
                      ) : (
                        <>
                          <PlayIcon size={16} />
                          Execute
                        </>
                      )}
                    </Button>
                  )}
                </>
              )}
              {selectedEdge && (
                <Button variant="destructive" onClick={deleteEdge}>
                  Delete Selected Edge
                </Button>
              )}
            </div>
            <div className="flex-grow" onDragOver={onDragOver} onDrop={onDrop}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onNodeClick={onNodeClick}
                onEdgeClick={onEdgeClick}
                nodeTypes={nodeTypes}
                defaultViewport={{ x: 0, y: 0, zoom: 0.9 }}
                minZoom={0.2}
                maxZoom={1.5}
                nodesDraggable
                proOptions={{ hideAttribution: true }}
                snapToGrid={true}
                preventScrolling={true}
                snapGrid={[10, 10]}
              >
                <Background />
                <Controls />
              </ReactFlow>
            </div>
          </div>
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel defaultSize={100}>
          <ConfigPanel
            selectedNode={selectedNode}
            onUpdateParameters={updateNodeParameters}
            onUpdateCredentials={updateNodeCredentials}
            onUpdateLabel={updateNodeLabel}
            onDelete={deleteNode}
            onUpdateActive={updateNodeActive}
            workflowSettings={workflowSettings}
            onUpdateWorkflowSettings={handleUpdateWorkflowSettings}
          />
        </ResizablePanel>
      </div>
    </ResizablePanelGroup>
  );
};

function WorkflowEditorWrapper() {
  return (
    <div className="h-[95vh] w-screen overflow-hidden">
      <ReactFlowProvider>
        <WorkflowEditor />
      </ReactFlowProvider>
    </div>
  );
}

export default WorkflowEditorWrapper;
