import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

import CreateProjectModal from "@/components/project/CreateProjectModal";
import { toast } from "@/components/ui/use-toast";
import { get, post, del } from "@/lib/rest";
import Logo from "../assets/logo.svg";
import { Trash } from "lucide-react";
import Loading from "@/custom/Loading/loading";
import { useAuth } from "@/hooks/auth/AuthContext";
import secureLocalStorage from "react-secure-storage";
import { useUserStore } from "@/lib/store";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

interface Project {
  id: number;
  name: string;
  organizationId: number;
  created_at: string;
  updated_at: string;
  configurations: {
    old_configuration: any;
    new_configuration: any;
  }[];
}

export function Dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setIsAuthenticated }: any = useAuth();
  const setUser = useUserStore((state: any) => state.setUser);

  const logout = () => {
    secureLocalStorage.clear();
    setIsAuthenticated("unauthenticated");
    navigate("/");
  };

  const [newProjectModal, setNewProjectModal] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<Project | null>(null);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await get({ url: "/api/project" });
      setProjects(response.data.projects);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchCurrentUser = () => {
    get({ url: "/auth/devme" })
      .then(response => {
        setUser(response.data.user);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCurrentUser();
    fetchProjects();
  }, []);

  const addProject = ({
    cloned,
    projectName,
    region,
    projectId,
    connectionUrl,
  }: {
    cloned: boolean;
    projectName: string;
    region?: string;
    projectId?: string;
    connectionUrl?: string;
  }) => {
    setLoading(true);
    const projectExists = projects.some(
      project => project.name === projectName,
    );

    if (projectExists) {
      setLoading(false);
      toast({
        variant: "destructive",
        title: "Please use a different project name.",
        description: "A project with this name already exists.",
      });

      return;
    }

    let data = cloned
      ? JSON.stringify({
          cloned: cloned,
          name: projectName,
          projectId: projectId,
          connectionUrl,
        })
      : JSON.stringify({
          cloned: cloned,
          name: projectName,
          region: region,
        });

    post({ url: "/api/project", data })
      .then(response => {
        fetchProjects();
        setNewProjectModal(false);
        toast({
          variant: "default",
          title: "Project created successfully",
        });
        // console.log(response);
        navigate(`/project/${response.data.projectId}/data`, {
          state: {
            projectId: response.data.projectId,
          },
        });
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        toast({
          variant: "destructive",
          title: "Failed to create project",
          description: "Please try again later",
        });
      });
  };

  const handleDeleteClick = (e: React.MouseEvent, project: Project) => {
    e.stopPropagation();
    setProjectToDelete(project);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (!projectToDelete) return;
    
    setLoading(true);
    del({ url: `/api/project/${projectToDelete.id}`, data: {} })
      .then(response => {
        fetchProjects();
        toast({
          variant: "default",
          title: "Project deleted successfully",
        });
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        toast({
          variant: "destructive",
          title: "Failed to delete project",
          description: "Please try again later",
        });
      })
      .finally(() => {
        setDeleteModalOpen(false);
        setProjectToDelete(null);
      });
  };

  const onClickRunMigration = async() => {
    await post({
      url: `/api/project/configuration/migrate`,
      data: {},
    });
  }

  const isOutOfSync = (configurations: Project['configurations']) => {
    if (!configurations || configurations.length !== 2) return true;
    return JSON.stringify(configurations[0].old_configuration) !== 
           JSON.stringify(configurations[1].old_configuration);
  };

  return (
    <div className="h-screen w-full overflow-hidden bg-slate-900 lg:min-h-[600px] xl:min-h-[800px]">
      <nav className="flex w-full items-center justify-between p-8">
        <img
          src={Logo}
          alt="logo"
          style={{ height: 15, width: "auto" }}
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
        <div>
          <Button
            variant={"outline"}
            className="mr-4"
            onClick={onClickRunMigration}
          >
            Run Migration
          </Button>
          <Button
            variant="destructive"
            className=""
            onClick={() => {
              logout();
            }}
          >
            Sign Out
          </Button>
        </div>
      </nav>
      {!loading ? (
        <div className="p-8">
          <div className="mb-4 flex justify-end">
            <Button
              className="bg-green-600 hover:bg-green-700"
              onClick={() => setNewProjectModal(true)}
            >
              + New Project
            </Button>
          </div>
          
          <div className="rounded-md border border-slate-700">
            <table className="w-full">
              <thead className="bg-slate-800">
                <tr>
                  <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Project ID</th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Project Name</th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">DB Sync Status</th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Created At</th>
                  <th className="px-4 py-3 text-left text-sm font-medium text-slate-300">Updated At</th>
                  <th className="px-4 py-3 text-right text-sm font-medium text-slate-300">Actions</th>
                </tr>
              </thead>
              <tbody>
                {projects?.map((project: Project) => (
                  <tr 
                    key={project.id}
                    onClick={() => {
                      navigate(`/project/${project.id}/data`, {
                        state: {
                          projectId: project.id,
                        },
                      });
                    }}
                    className="cursor-pointer border-t border-slate-700 hover:bg-slate-800"
                  >
                    <td className="px-4 py-3 text-left text-sm text-slate-300">{project.id}</td>
                    <td className="px-4 py-3 text-left text-sm text-slate-300">{project.name}</td>
                    <td className="px-4 py-3 text-left text-sm">
                      <span className={`inline-flex rounded-full px-2 py-1 text-xs font-semibold ${
                        isOutOfSync(project.configurations) 
                          ? 'bg-red-900/50 text-red-400' 
                          : 'bg-green-900/50 text-green-400'
                      }`}>
                        {isOutOfSync(project.configurations) ? 'Out of sync' : 'In sync'}
                      </span>
                    </td>
                    <td className="px-4 py-3 text-left text-sm text-slate-300">
                      {new Date(project.created_at).toLocaleString()}
                    </td>
                    <td className="px-4 py-3 text-left text-sm text-slate-300">
                      {new Date(project.updated_at).toLocaleString()}
                    </td>
                    <td className="px-4 py-3 text-right">
                      <Button
                        variant="ghost"
                        className="text-red-400 hover:text-red-300"
                        onClick={(e) => handleDeleteClick(e, project)}
                      >
                        <Trash className="h-4 w-4" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Loading />
      )}
      {newProjectModal && (
        <CreateProjectModal
          setNewProjectModal={setNewProjectModal}
          addProject={addProject}
          loading={loading}
        />
      )}
      <AlertDialog open={deleteModalOpen} onOpenChange={setDeleteModalOpen}>
        <AlertDialogContent className="bg-slate-900 text-slate-300">
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription className="text-slate-400">
              This will permanently delete the project "
              {projectToDelete?.name}". This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel 
              onClick={() => setProjectToDelete(null)}
              className="bg-slate-800 text-slate-300 hover:bg-slate-700"
            >
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={confirmDelete}
              className="bg-red-600 text-white hover:bg-red-700"
            >
              Delete Project
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
