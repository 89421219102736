import Editor from '@monaco-editor/react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import StructuredResultView from './StructuredResultView';

interface ResultViewerProps {
  value: any;
}

const ResultViewer = ({ value }: ResultViewerProps) => {
  const jsonString = typeof value === 'object' 
    ? JSON.stringify(value, null, 2)
    : String(value);

  return (
    <div className="min-w-[300px]">
      <Tabs defaultValue="raw" className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="structured">Structured</TabsTrigger>
          <TabsTrigger value="raw">Raw</TabsTrigger>
        </TabsList>
        <TabsContent value="structured">
          <div className="border rounded-md">
            <StructuredResultView value={value} />
          </div>
        </TabsContent>
        <TabsContent value="raw">
          <div className="border rounded-md overflow-hidden">
            <Editor
              height="200px"
              defaultLanguage="json"
              value={jsonString}
              options={{
                readOnly: true,
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                lineNumbers: 'off',
                folding: true,
                wordWrap: 'on',
                fontSize: 11,
                renderLineHighlight: 'none',
                contextmenu: false,
                overviewRulerLanes: 0,
                hideCursorInOverviewRuler: true,
                scrollbar: {
                  vertical: 'auto',
                  horizontal: 'auto'
                }
              }}
            />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default ResultViewer; 