import React, { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CredentialType, IWorkflowCredential } from '@/interface/workflow';
import { get } from '@/lib/rest';
import { useParams } from 'react-router-dom';

interface CredentialSelectorProps {
  value: string;
  onChange: (value: string) => void;
  credentialType?: CredentialType;
}

export const CredentialSelector: React.FC<CredentialSelectorProps> = ({
  value,
  onChange,
  credentialType
}) => {
    const { projectId } = useParams();
  const [credentials, setCredentials] = useState<IWorkflowCredential[]>([]);

  useEffect(() => {
    const fetchCredentials = async () => {
        try {
          const response = await get({ url: `/api/project/${projectId}/credential` });
          setCredentials(response?.data?.credentials || []);
        } catch (error) {
          console.error(error);
        }
      };

    fetchCredentials();
  }, [projectId, credentialType]);

  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select credential" />
      </SelectTrigger>
      <SelectContent>
        {credentials.length === 0 ? (
          <SelectItem value="no-credentials" disabled>
            No credentials available
          </SelectItem>
        ) : (
          credentials.filter(credential => credential.type === credentialType).map((credential) => (
            <SelectItem key={credential.id} value={credential.id}>
              {credential.name}
            </SelectItem>
          ))
        )}
      </SelectContent>
    </Select>
  );
}; 