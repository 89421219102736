import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { BaseInputProps } from "./types";

export const SelectInput: React.FC<BaseInputProps> = ({ value, onChange, schema }) => {
  const options = schema.ui?.options || [];
  
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select an option" />
      </SelectTrigger>
      <SelectContent>
        {options.map((option: any) => (
          <SelectItem 
            key={option.value || option} 
            value={option.value || option}
          >
            {option.label || option}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}; 