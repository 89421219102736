import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { get, put } from "@/lib/rest";
import DomainModal from "@/components/setttings/DomainModal";
import MemberModal from "@/components/setttings/MemberModal";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useUserStore } from "@/lib/store";
import { toast } from "@/components/ui/use-toast";
import ConstantModal from "@/components/settings/ConstantModal";

enum ModalType {
  None = "None",
  AddMember = "AddMember",
  UpdateMember = "UpdateMember",
  CreateDomain = "CreateDomain",
  EditDomain = "EditDomain",
  CreateConstant = "CreateConstant",
  EditConstant = "EditConstant",
}

const userHeader = ["email", "firstName", "lastName", "role"];
const domainHeader = ["domain", "environment"];
const constantHeader = ["key", "value", "environment"];

const Settings = () => {
  const { projectId } = useParams();
  const [showModal, setShowModal] = useState<ModalType>(ModalType.None);
  const [selectedMenu, setSelectedMenu] = useState("users");
  const [domains, setDomains] = useState([]);
  const [members, setMembers] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);
  const [environments, setEnvironments] = useState<any>([]);
  const [selectedDomain, setSelectedDomain] = useState<any>();
  const [selectedMember, setSelectedMember] = useState<any>();
  const [loading, setLoading] = useState(false);
  const currentUser: any = useUserStore((state: any) => state.user);
  const setUser = useUserStore((state: any) => state.setUser);
  const [localUser, setLocalUser] = useState(currentUser);
  const [constants, setConstants] = useState([]);
  const [selectedConstant, setSelectedConstant] = useState<any>();

  useEffect(() => {
    fetchMembers();
    fetchOrgUsers();
    fetchEnvironments();
    fetchDomains();
    fetchConstants();
  }, []);

  const onClickDomain = (clickedDomain: any) => {
    setSelectedDomain(clickedDomain);
    setShowModal(ModalType.EditDomain);
  };

  const onClickMember = (clickedMember: any) => {
    setSelectedMember(clickedMember);
    setShowModal(ModalType.UpdateMember);
  };

  const onClickSaveProfile = async () => {
    try {
      await put({
        url: `/api/project/${projectId}/user/${localUser.id}`,
        data: {
          email: localUser.email,
          first_name: localUser.first_name,
          last_name: localUser.last_name,
          password:
            localUser?.password?.trim() !== "" ? localUser.password : null,
        },
      });
      toast({
        variant: "default",
        title: "Profile saved successfully",
      });
      get({ url: "/auth/devme" })
        .then(response => {
          setUser(response.data.user);
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDomains = async () => {
    setLoading(true);
    try {
      const response = await get({ url: `/api/project/${projectId}/domain` });
      setDomains(response.data.domains);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchMembers = async () => {
    setLoading(true);
    try {
      const response = await get({ url: `/api/project/${projectId}/member` });
      setMembers(response.data.members);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchOrgUsers = async () => {
    setLoading(true);
    try {
      const response = await get({ url: `/api/project/${projectId}/orgUsers` });
      setOrgUsers(response.data?.orgUsers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchEnvironments = async () => {
    setLoading(true);
    try {
      const response = await get({
        url: `/api/project/${projectId}/environment`,
      });
      setEnvironments(response.data.environments);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchConstants = async () => {
    setLoading(true);
    try {
      const response = await get({ url: `/api/project/${projectId}/constant` });
      setConstants(response.data.constants);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const closeModal = () => {
    fetchDomains();
    fetchMembers();
    fetchConstants();
    setShowModal(ModalType.None);
  };

  function renderColumnHeader(name: string) {
    return (
      <TableHead
        key={name}
        className="h-10 w-40 items-center border-r p-3 text-black"
      >
        <div className="flex items-center justify-between">
          {" "}
          <div className="flex items-center">
            <>{name}</>
          </div>
        </div>
      </TableHead>
    );
  }

  function renderCell(key: string, value: string) {
    return (
      <TableCell key={key} className={`h-10 border-r p-3`}>
        <div className="flex w-40 justify-start truncate">
          <span>{value}</span>
        </div>
      </TableCell>
    );
  }

  const onClickConstant = (clickedConstant: any) => {
    setSelectedConstant(clickedConstant);
    setShowModal(ModalType.EditConstant);
  };

  return (
    <div className="h-full w-full overflow-hidden">
      <div className="min-h-[calc(100vh-48px)] w-screen p-4">
        <div className="flex h-full">
          <Card className="mr-6 mt-6 h-[calc(100vh-152px)] min-w-60 overflow-y-scroll">
            <CardHeader>
              <CardTitle>Settings</CardTitle>
            </CardHeader>
            <CardContent className="p-4 pt-0">
              <nav className="text-muted-foreground grid gap-4 text-sm">
                <div
                  className="flex cursor-pointer items-center justify-between text-left"
                  onClick={() => {
                    setSelectedMenu("users");
                  }}
                >
                  <p
                    className={`${selectedMenu === "users" ? "rounded-md bg-green-300 px-2 py-1" : "rounded-md px-2 py-1 text-neutral-900"}`}
                  >
                    Members
                  </p>
                </div>
                <div
                  className="flex cursor-pointer items-center justify-between text-left"
                  onClick={() => {
                    setSelectedMenu("domains");
                  }}
                >
                  <p
                    className={`${selectedMenu === "domains" ? "rounded-md bg-green-300 px-2 py-1" : "rounded-md px-2 py-1 text-neutral-900"}`}
                  >
                    Domains
                  </p>
                </div>
                <div
                  className="flex cursor-pointer items-center justify-between text-left"
                  onClick={() => {
                    setSelectedMenu("constants");
                  }}
                >
                  <p
                    className={`${selectedMenu === "constants" ? "rounded-md bg-green-300 px-2 py-1" : "rounded-md px-2 py-1 text-neutral-900"}`}
                  >
                    Constants
                  </p>
                </div>
                <div
                  className="flex cursor-pointer items-center justify-between text-left"
                  onClick={() => {
                    setSelectedMenu("profile");
                  }}
                >
                  <p
                    className={`${selectedMenu === "profile" ? "rounded-md bg-green-300 px-2 py-1" : "rounded-md px-2 py-1 text-neutral-900"}`}
                  >
                    Profile
                  </p>
                </div>
              </nav>
            </CardContent>
          </Card>
          <div className="relative mt-6 overflow-x-scroll rounded-lg border border-slate-300">
            {(selectedMenu === "domains" || selectedMenu === "users" || selectedMenu === "constants") && (
              <Table className="h-[calc(100vh-182px)] w-full overflow-y-scroll rounded-t-lg text-[14px] font-medium text-black">
                <TableHeader className="relative h-[42px] w-full bg-slate-100">
                  <TableRow className="relative">
                    {selectedMenu === "users"
                      ? userHeader.map((column: string) =>
                          renderColumnHeader(column),
                        )
                      : selectedMenu === "domains"
                      ? domainHeader.map((column: string) =>
                          renderColumnHeader(column),
                        )
                      : constantHeader.map((column: string) =>
                          renderColumnHeader(column),
                        )}
                    <TableHead className="h-[30px] w-fit border-r px-2 text-black">
                      <Button
                        variant={"default"}
                        className="m-auto h-8 w-8 p-px hover:bg-slate-200"
                        onClick={() =>
                          setShowModal(
                            selectedMenu === "users"
                              ? ModalType.AddMember
                              : selectedMenu === "domains"
                              ? ModalType.CreateDomain
                              : ModalType.CreateConstant
                          )
                        }
                      >
                        <Plus className="h-4 w-4 cursor-pointer rounded-lg p-0" />
                      </Button>
                    </TableHead>
                  </TableRow>
                </TableHeader>

                <TableBody className="h-fit">
                  {!loading &&
                    selectedMenu === "users" &&
                    members.map((rowData: any) => (
                      <TableRow
                        className="h-11 cursor-pointer"
                        onClick={() => onClickMember(rowData)}
                      >
                        {renderCell(rowData.email, rowData.email)}
                        {renderCell(rowData.first_name, rowData.first_name)}
                        {renderCell(rowData.last_name, rowData.last_name)}
                        {renderCell(rowData.role, rowData.role)}
                      </TableRow>
                    ))}
                  {!loading &&
                    selectedMenu === "domains" &&
                    domains.map((rowData: any) => (
                      <TableRow
                        className="h-11 cursor-pointer"
                        onClick={() => onClickDomain(rowData)}
                      >
                        {renderCell(rowData.domain_name, rowData.domain_name)}
                        {renderCell(
                          environments.filter(
                            (env: any) => env.id === rowData.environment_id,
                          )[0]?.name,
                          environments.filter(
                            (env: any) => env.id === rowData.environment_id,
                          )[0]?.name,
                        )}
                      </TableRow>
                    ))}
                  {!loading &&
                    selectedMenu === "constants" &&
                    constants.map((rowData: any) => (
                      <TableRow
                        className="h-11 cursor-pointer"
                        onClick={() => onClickConstant(rowData)}
                      >
                        {renderCell(rowData.key, rowData.key)}
                        {renderCell(rowData.value, rowData.value)}
                        {renderCell(
                          environments.filter(
                            (env: any) => env.id === rowData.environment_id
                          )[0]?.name,
                          environments.filter(
                            (env: any) => env.id === rowData.environment_id
                          )[0]?.name
                        )}
                      </TableRow>
                    ))}
                  <TableRow />
                </TableBody>
              </Table>
            )}
            {selectedMenu === "profile" && (
              <div className="grid w-[calc(100vw-300px)] grid-cols-4 gap-4 p-16">
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Email</Label>
                  <Input
                    type="text"
                    id={`email`}
                    placeholder="email"
                    value={localUser.email || ""}
                    onChange={e =>
                      setLocalUser({ ...localUser, email: e.target.value })
                    }
                  />
                </div>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">First Name</Label>
                  <Input
                    type="text"
                    id={`firstName`}
                    placeholder="first name"
                    value={localUser.first_name || ""}
                    onChange={e =>
                      setLocalUser({ ...localUser, first_name: e.target.value })
                    }
                  />
                </div>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Last Name</Label>
                  <Input
                    type="text"
                    id={`lastName`}
                    placeholder="last name"
                    value={localUser.last_name || ""}
                    onChange={e =>
                      setLocalUser({ ...localUser, last_name: e.target.value })
                    }
                  />
                </div>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Role</Label>
                  <Input
                    type="text"
                    id={`role`}
                    placeholder="role"
                    value={localUser.role || ""}
                    disabled={true}
                    onChange={e =>
                      setLocalUser({ ...localUser, role: e.target.value })
                    }
                  />
                </div>
                <div className="col-span-2 flex flex-col space-y-2">
                  <Label className="w-fit">Password</Label>
                  <Input
                    type="password"
                    id={`password`}
                    placeholder="password"
                    value={localUser.password || ""}
                    onChange={e =>
                      setLocalUser({ ...localUser, password: e.target.value })
                    }
                  />
                </div>
                <div className="col-span-4 mt-8 flex flex-col space-y-2">
                  <Button
                    className="w-fit"
                    variant="default"
                    onClick={onClickSaveProfile}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showModal === ModalType.CreateDomain && (
        <DomainModal
          domain={{
            domain_name: "",
            environment_id: environments.filter(
              (env: any) => env.name === "dev",
            )[0]?.id,
          }}
          closeAction={closeModal}
          isEditMode={false}
          environments={environments}
          projectId={projectId}
        />
      )}
      {showModal === ModalType.EditDomain && (
        <DomainModal
          domain={selectedDomain}
          closeAction={closeModal}
          isEditMode={true}
          environments={environments}
          projectId={projectId}
        />
      )}
      {showModal === ModalType.AddMember && (
        <MemberModal
          member={{
            email: "",
            first_name: "",
            last_name: "",
            role: "",
            password: "",
          }}
          orgUsers={orgUsers.filter(
            (user: any) =>
              !members.map((member: any) => member.email).includes(user.email),
          )}
          closeAction={closeModal}
          isEditMode={false}
          projectId={projectId}
        />
      )}
      {showModal === ModalType.UpdateMember && (
        <MemberModal
          member={selectedMember}
          orgUsers={orgUsers}
          closeAction={closeModal}
          isEditMode={true}
          projectId={projectId}
        />
      )}
      {showModal === ModalType.CreateConstant && (
        <ConstantModal
          constant={{
            key: "",
            value: "",
            environment_id: environments.filter(
              (env: any) => env.name === "dev"
            )[0]?.id,
          }}
          closeAction={closeModal}
          isEditMode={false}
          environments={environments}
          projectId={projectId}
        />
      )}
      {showModal === ModalType.EditConstant && (
        <ConstantModal
          constant={selectedConstant}
          closeAction={closeModal}
          isEditMode={true}
          environments={environments}
          projectId={projectId}
        />
      )}
    </div>
  );
};

export default Settings;
